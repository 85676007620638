import { Box, CircularProgress, LinearProgress, Typography } from "@mui/material";

function CircularProgressWithLabel(props) {
   return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
         <CircularProgress variant='determinate' {...props} />
         <Box
            sx={{
               top: 0,
               left: 0,
               bottom: 0,
               right: 0,
               position: 'absolute',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
            }}
         >
            <Typography variant='caption' component='div' color='text.secondary'>
               {`${Math.round(props.value)}%`}
            </Typography>
         </Box>
      </Box>
   );
}

function LinearProgressWithLabel(props) {
   return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
         <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant='determinate' {...props} />
         </Box>
         <Box sx={{ minWidth: 35 }}>
            <Typography variant='body2' color='text.secondary'>{`${Math.round(
               props.value
            )}%`}</Typography>
         </Box>
      </Box>
   );
}

export {LinearProgressWithLabel, CircularProgressWithLabel}