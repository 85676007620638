import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import ShopifyLayout from 'src/layouts/shopify/ShopifyLayout';

const ShopifyCreateMigration = lazy(() => import('src/pages/ShopifyApp/ShopifyCreateMigration'));
const ShopifyConfiguration = lazy(() => import('src/pages/ShopifyApp/ShopifyConfiguration'));
const ShopifyDemoMigration = lazy(() => import('src/pages/ShopifyApp/ShopifyDemoMigration'));
const ShopifyFullMigration = lazy(() => import('src/pages/ShopifyApp/ShopifyFullMigration'));
const ShopifyDemoRunning = lazy(() => import('src/pages/ShopifyApp/ShopifyDemoRunning'));
const ShopifyCompleteMig = lazy(() => import('src/pages/ShopifyApp/ShopifyCompleteMig'));
const ShopifyMiragtionList = lazy(() => import('src/pages/ShopifyApp/ShopifyMiragtionList'));
const ShopifyConfirmMigration = lazy(() => import('src/pages/ShopifyApp/ShopifyConfirmMigration'));

export const shopifyRoutes = [
   {
      path: '/app-shopify/',
      element: (
         <AppProvider i18n={enTranslations}>
            <ShopifyLayout>
               <Suspense>
                  <Outlet />
               </Suspense>
            </ShopifyLayout>
         </AppProvider>
      ),
      children: [
         {
            index: true,
            element: <ShopifyCreateMigration />,
         },
         {
            path: 'create-migration/:migration_id',
            element: <ShopifyCreateMigration />,
         },
         {
            path: 'migration-list',
            element: <ShopifyMiragtionList />,
         },
         {
            path: 'configuration',
            element: <ShopifyConfiguration />,
         },
         {
            path: 'configuration/:migration_id',
            element: <ShopifyConfiguration />,
         },
         {
            path: 'migration',
            element: <ShopifyDemoMigration />,
         },
         {
            path: 'migration/:migration_id',
            element: <ShopifyDemoMigration />,
         },
         {
            path: 'full-migration',
            element: <ShopifyFullMigration />,
         },
         {
            path: 'full-migration/:migration_id',
            element: <ShopifyFullMigration />,
         },
         {
            path: 'demo-running',
            element: <ShopifyDemoRunning />,
         },
         {
            path: 'demo-running/:migration_id',
            element: <ShopifyDemoRunning />,
         },
         {
            path: 'complete-migration',
            element: <ShopifyCompleteMig />,
         },
         {
            path: 'complete-migration/:migration_id',
            element: <ShopifyCompleteMig />,
         },
         {
            path: 'confirm-recent',
            element: <ShopifyConfirmMigration />,
         },
         {
            path: 'confirm-recent/:migration_id',
            element: <ShopifyConfirmMigration />,
         },
         {
            path: 'confirm-smart',
            element: <ShopifyConfirmMigration />,
         },
         {
            path: 'confirm-smart/:migration_id',
            element: <ShopifyConfirmMigration />,
         },
         {
            path: 'remigrate',
            element: <ShopifyCreateMigration />,
         },
         {
            path: 'remigrate/:migration_id',
            element: <ShopifyCreateMigration />,
         },
      ],
   },
];
