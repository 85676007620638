import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

function CustomLoading(props) {
   const { loadingText } = props;

   return (
      <React.Fragment>
         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack direction='column' alignItems='center' spacing={2}>
               <CircularProgress />
               {loadingText && <Typography variant='subtitle2'>{loadingText}</Typography>}
            </Stack>
         </Box>
      </React.Fragment>
   );
}

export default CustomLoading;
