import React from 'react';
import { useField } from 'formik';
import { CustomInput } from '../CustomComponents/Custom';

const regex = /^[0-9\b]+$/;

function PhoneNumber() {
   const [
      { value: phoneNumber },
      { error: errorPhoneNumber, touched },
      { setValue: setPhoneNumber },
   ] = useField('phone');

   const applyChange = (value) => {
      if (value === '' || regex.test(value)) setPhoneNumber(value);
   };

   return (
      <CustomInput
         value={phoneNumber}
         name="phone"
         error={touched && errorPhoneNumber ? errorPhoneNumber : null}
         helperText={touched && errorPhoneNumber ? errorPhoneNumber : null}
         fullWidth
         label={
            <label>
               Phone/WhatsApp<span style={{ color: 'red' }}>*</span>
            </label>
         }
         onChange={(e) => applyChange(e.target.value)}
      />
   );
}

export default PhoneNumber;
