import React, { memo } from 'react';
import {
   Card,
   CardContent,
   CardHeader,
   Divider,
   Skeleton,
   Stack,
   Typography,
   keyframes,
} from '@mui/material';
import { useSettings } from 'src/hooks/use-settings';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import DefaultGrow from '../CustomComponents/DefaultGrow';
import { useAuth } from 'src/hooks/use-auth';
import { useInstruction } from 'src/hooks/useInstruction';

const MigrationInstruction = memo(function MigrationInstruction(props, ref) {
   const { instructionKey, stickyTop, smallestPoint } = props;

   const settings = useSettings();
   const theme = useTheme();

   const { user } = useAuth();
   const email = user?.user?.email;

   const { headerWidth, instructions } = useInstruction(smallestPoint);

   const instructionContent = instructions
      ?.find((ins) => ins.code === instructionKey)
      ?.content?.replace('{{user_email}}', `<a href="mailto:${email}">${email}</a>`);

   const instructionName = instructions?.find((ins) => ins.code === instructionKey)?.name;

   const backgroundColor = settings.paletteMode === 'light' ? 'neutral.200' : 'neutral.700';

   const glow = keyframes`
      from {
         background: ${
            settings.paletteMode === 'light'
               ? theme.palette.primary.light
               : theme.palette.primary.darkest
         };
      }
      to {
         background: ${theme.palette.background.paper};
      }
   `;

   const GlowCardContent = styled(CardContent)({
      animation: `${glow} 2s 1 ease`,
   });

   const renderInstructionHeader = () => {
      if (instructionName) return <Typography variant="h6">{instructionName}</Typography>;
      return <Skeleton variant="text" sx={{ height: 20 }} />;
   };

   const renderInstructionContent = () => {
      if (instructionContent)
         return (
            <Typography
               sx={{ fontSize: '0.875rem' }}
               dangerouslySetInnerHTML={{ __html: instructionContent }}
            />
         );
      return <Skeleton variant="string" sx={{ height: 200 }} />;
   };

   return (
      <DefaultGrow onMount={Boolean(instructions)}>
         <Stack spacing={4} sx={{ position: 'sticky', top: stickyTop ?? '4.25rem' }}>
            <Card>
               <CardHeader
                  sx={{
                     backgroundColor: backgroundColor,
                     padding: headerWidth.padding,
                  }}
                  title={
                     <Stack alignItems="center" direction="row">
                        <img src={'/assets/lifesaver.svg'} style={headerWidth.image} />
                        <Typography variant="h5">Instruction</Typography>
                     </Stack>
                  }
               />
               <Divider />

               <GlowCardContent>
                  <Stack spacing={1}>
                     {renderInstructionHeader()}
                     {renderInstructionContent()}
                  </Stack>
               </GlowCardContent>
            </Card>
            {/* <img
               src={adsImage?.img_url}
               style={{ borderRadius: '12px', cursor: 'pointer' }}
               onClick={() => window.open(adsImage?.link)}
            /> */}
         </Stack>
      </DefaultGrow>
   );
});

export default MigrationInstruction;
