import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Seo } from 'src/components/seo';
import { useSettings } from 'src/hooks/use-settings';
import CustomStepper from './Common/CustomStepper';
import DemoProcess from './DemoRunning/DemoProcess';
import { useState } from 'react';
import { demoMigrationServices } from 'src/api/services/demoMigration';
import { useEffect } from 'react';
import MigrationHelper from './Common/MigrationHelper';
import { useRef } from 'react';
import { usePageView } from 'src/hooks/use-page-view';
import MigrationInstruction from '../MigrationIntruction/MigrationInstruction';
import { useAlert } from 'src/hooks/useAlert';
import { useMigrationId } from 'src/hooks/useMigrationId';

function DemoRunning() {
   const settings = useSettings();

   const migrationId = useMigrationId(2); //To get id from the URL

   usePageView();

   const alert = useAlert();

   const [migrationData, setMigrationData] = useState({});
   const [errorMessage, setErrorMessage] = useState('');

   const targetStore = migrationData?.notice?.target?.cart_type;

   const timer = useRef();

   const getMigrationNotice = async () => {
      await demoMigrationServices.get.getMigrationNotice(migrationId).then((res) => {
         if (res.data.success) {
            setMigrationData(res.data.data);
            
            const status = res.data.data.status_migration;

            // if (res.data.data.notice.finish || !res.data.data.notice.running)
            if ([3, 5].includes(status)) clearInterval(timer.current);
         } else {
            console.log('error');
            setErrorMessage(() => res.data.message);
            alert('error', res.data.message);
            clearInterval(timer.current);
         }
      });
   };

   useEffect(() => {
      getMigrationNotice();

      timer.current = setInterval(getMigrationNotice, 10000);

      return () => clearInterval(timer.current);
      // eslint-disable-next-line
   }, [migrationId]);

   return (
      <React.Fragment>
         <Seo title="Demo Migration Running" />
         <Box
            component="main"
            sx={{
               flexGrow: 1,
               py: 8,
            }}
         >
            <Container maxWidth={settings.stretch ? false : 'xl'}>
               <Grid
                  container
                  spacing={{
                     xs: 3,
                     lg: 4,
                  }}
               >
                  {/* Stepper */}
                  <CustomStepper index={1} />

                  {/* Content */}
                  <Grid item xs={8}>
                     <Stack spacing={3}>
                        {/* Header */}
                        <Typography variant="h4">Demo Migration Is Running</Typography>

                        {/* Migration Process */}
                        <DemoProcess
                           data={migrationData}
                           migrationId={migrationId}
                           errorMessage={errorMessage}
                        />

                        {/* Helper */}
                        <MigrationHelper isDemo targetStore={targetStore} />
                     </Stack>
                  </Grid>

                  {/* Instruction */}
                  <Grid item xs={4}>
                     <MigrationInstruction instructionKey="full_migration_notice" />
                  </Grid>
               </Grid>
            </Container>
         </Box>
      </React.Fragment>
   );
}

export default DemoRunning;
