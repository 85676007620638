import { Stack, Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { memo } from 'react';
import DefaultGrow from '../CustomComponents/DefaultGrow';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

function MigrationStep(props, ref) {
   const { index, appear, stepType = '' } = props;

   const { t } = useTranslation();

   const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

   const steps = [
      t(tokens.nav.setup),
      'Demo Migration',
      t(tokens.nav.fullMigration),
      t(tokens.nav.completed),
   ];
   const recentSteps = [
      t(tokens.nav.fullMigration),
      t(tokens.nav.confirmation),
      t(tokens.nav.recentMigration),
      t(tokens.nav.completed),
   ];
   const smartSteps = [
      t(tokens.nav.fullMigration),
      t(tokens.nav.confirmation),
      t(tokens.nav.smartUpdate),
      t(tokens.nav.completed),
   ];

   const realStep = stepType === 'recent' ? recentSteps : stepType === 'smart' ? smartSteps : steps;

   return (
      <DefaultGrow appear={appear}>
         <Stack alignItems="center">
            <Box sx={{ width: mdUp ? '700px' : '100%', px: 4 }}>
               <Stepper activeStep={index}>
                  {realStep.map((step) => (
                     <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                     </Step>
                  ))}
               </Stepper>
            </Box>
         </Stack>
      </DefaultGrow>
   );
}

export default memo(MigrationStep);
