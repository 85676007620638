import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from './hooks/use-auth';

function GlobalLoading() {
   const { globalLoading } = useSelector((state) => state.globalLoading);

   const { initializing } = useAuth();

   if (!globalLoading && !initializing) return null;

   return (
      <React.Fragment>
         <Box
            sx={{
               position: 'fixed',
               width: '100%',
               height: '100%',
               background: '#ffffff',
               opacity: 0.5,
            }}
         />
         <CircularProgress
            sx={{
               position: 'fixed',
               top: '50%',
               left: '50%',
               zIndex: 10,
            }}
         />
      </React.Fragment>
   );
}

export default GlobalLoading;
