import cookies from 'src/api/services/cookies';

const cookiesCache = [
   'access_token',
   'shopify_host',
   'shopline_access_token',
   'bigcom_token',
   'squareup_token',
   'cafe_access_token',
   'cafe_refresh_token',
   'wix_access_token',
   'wix_refresh_token',
];

const localCache = [
   'loginFromAdmin',
   'loginFromShopify',
   'loginFromShopline',
   'loginFromWix',
   'loginFromBigCom',
   'loginFromCafe',

   'authorizeFromSquareup',

   'shopify_app_id',
   'target_shop',
   'bigcom_api_path',
   'bigcom_source',
   'bigcom_client_id',
   'cafe_mall_id',
   'wix_user_id',
   'wix_cart_url',
];

const sessionCache = ['wix_access_token'];

export const cleanupCache = () => {
   cookiesCache.forEach((cookie) => {
      cookies.remove(cookie);
   });

   localCache.forEach((local) => {
      localStorage.removeItem(local);
   });

   sessionCache.forEach((local) => {
      sessionStorage.removeItem(local);
   });
};
