import { Box, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Seo } from 'src/components/seo';
import { useSettings } from 'src/hooks/use-settings';
import CustomerType from './Welcome/CustomerType';
import { accountServices } from 'src/api/services/accounts';
import { useAlert } from 'src/hooks/useAlert';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useAuth } from 'src/hooks/use-auth';
import { paths } from 'src/paths';
import CompanyName from './Welcome/CompanyName';
import PhoneNumber from './Welcome/PhoneNumber';
import Country from './Welcome/Country';

function Welcome() {
   const settings = useSettings();

   const initialValues = {
      category: '',
      phone: '',
      company: '',
      country: '',
   };

   const alert = useAlert();
   const { user } = useAuth();
   const isUpdateInfor = user?.user?.is_update_cate;

   const [loading, setLoading] = useState(false);

   const validationSchema = Yup.object({
      phone: Yup.string().required('Phone/WhatsApp is required'),
      category: Yup.string().required('Category is required'),
      country: Yup.string().required('Country is required'),
   });

   useEffect(() => {
      if (isUpdateInfor) window.location.href = paths.dashboard.index;
      // eslint-disable-next-line
   }, [isUpdateInfor]);

   const submitWelcome = async (values) => {
      setLoading((current) => !current);
      try {
         await accountServices.post.customerUpdate(values).then((res) => {
            if (res && res.data.success) {
               alert('success', 'Update Information Success');
               if (user.user.migration_count === 0)
                  window.location.href = paths.dashboard.createMigration;
               else window.location.href = paths.dashboard.index;
            }
         });
      } catch (error) {
         console.log(error);
         alert('error', 'Update Information Fail');
      }
      setLoading((current) => !current);
   };

   return (
      <React.Fragment>
         <Seo title="Welcome to LitExtension" />
         <Box
            component="main"
            sx={{
               flexGrow: 1,
               py: 8,
            }}
         >
            <Container maxWidth={settings.stretch ? false : 'xl'} id="welcome">
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                     <Stack spacing={3}>
                        <Typography variant="h4" textAlign="center">
                           Welcome to LitExtension
                        </Typography>
                        <Typography variant="body1" textAlign="center">
                           Thank you for registering. Just a quick more step and you are ready for a
                           great data migration experience.
                        </Typography>
                     </Stack>
                  </Grid>

                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                     <Card sx={{ width: '50%' }}>
                        <CardContent>
                           <Formik
                              initialValues={initialValues}
                              onSubmit={(values) => submitWelcome(values)}
                              validationSchema={validationSchema}
                           >
                              {(formik) => (
                                 <Form>
                                    <Stack spacing={4}>
                                       <CustomerType />
                                       <PhoneNumber />
                                       <Country />
                                       <CompanyName />

                                       <Stack alignItems="center">
                                          <LoadingButton
                                             variant="contained"
                                             size="small"
                                             type="submit"
                                             loading={loading}
                                          >
                                             Submit
                                          </LoadingButton>
                                       </Stack>
                                    </Stack>
                                 </Form>
                              )}
                           </Formik>
                        </CardContent>
                     </Card>
                  </Grid>
               </Grid>
            </Container>
         </Box>
      </React.Fragment>
   );
}

export default Welcome;
