import { tokens } from '../tokens';

export const de = {
   [tokens.nav.profile]: 'Profil',
   [tokens.nav.myMigration]: 'Meine Migrationen',
   [tokens.nav.createMigration]: 'Migration erstellen',
   [tokens.nav.allIn]: 'Alles-in-Einem Migration',
   [tokens.nav.topUpBalance]: 'Guthaben aufladen',
   [tokens.nav.orderHistory]: 'Bestellverlauf',
   [tokens.nav.setup]: 'Einrichtung',
};
