export const tokens = {
   common: {
      languageChanged: 'common.languageChanged',
   },
   nav: {
      checkout: 'nav.checkout',
      setup: 'nav.setup',
      profile: 'nav.profile',
      myMigration: 'nav.myMigration',
      createMigration: 'nav.createMigration',
      allIn: 'nav.allIn',
      topUpBalance: 'nav.topUpBalance',
      orderHistory: 'nav.orderHistory',
      downloadInvoice: 'nav.downloadInvoice',
      sla: 'nav.sla',
      logout: 'nav.logout',
      customMigration: 'nav.customMigration',
      migrationCustomization: 'nav.migrationCustomization',
      premiumSubscription: 'nav.premiumSubscription',
      premiumSupport: 'nav.premiumSupport',
      helpCenter: 'nav.helpCenter',
      reportProblem: 'nav.reportProblem',
      paymentGuide: 'nav.paymentGuide',
      myTickets: 'nav.myTickets',
      createNewMigration: 'nav.createNewMigration',
      continueMigration: 'nav.continueMigration',
      recentMigration: 'nav.recentMigration',
      remigrate: 'nav.remigrate',
      smartUpdate: 'nav.smartUpdate',
      viewProgress: 'nav.viewProgress',
      new: 'nav.new',
      full: 'nav.full',
      recent: 'nav.recent',
      migrationSetup: 'nav.migrationSetup',
      migrationConfiguration: 'nav.migrationConfiguration',
      demoMigrationCompleted: 'nav.demoMigrationCompleted',
      migrationConfirmation: 'nav.migrationConfirmation',
      migrationRunning: 'nav.migrationRunning',
      migrationCompleted: 'nav.migrationCompleted',
      availableActions: 'nav.availableActions',
      fullMigration: 'nav.fullMigration',
      restartMigrationFailed: 'nav.restartMigrationFailed',
      restartMigrationSucceeded: 'nav.restartMigrationSucceeded',
      startMigrationFailed: 'nav.startMigrationFailed',
      pauseMigrationFailed: 'nav.pauseMigrationFailed',
      fullMigrationIsRunning: 'nav.fullMigrationIsRunning',
      instruction: 'nav.instruction',
      getAdditionalOptionsFailed: 'nav.getAdditionalOptionsFailed',
      warning: 'nav.warning',
      enter: 'nav.enter',
      howToGet: 'nav.howToGet',
      key: 'nav.key',
      howToGetAPIKeyFromNeto: 'nav.howToGetAPIKeyFromNeto',
      autoUploadConnector: 'nav.autoUploadConnector',
      manualUploadConnector: 'nav.manualUploadConnector',
      sourceCartType: 'nav.sourceCartType',
      targetCartType: 'nav.targetCartType',
      sourceCartURL: 'nav.sourceCartURL',
      targetCartURL: 'nav.targetCartURL',
      sourceCartSetup: 'nav.sourceCartSetup',
      targetCartSetup: 'nav.targetCartSetup',
      completed: 'nav.completed',
      confirmation: 'nav.confirmation',
      fullMigrationCompleted: 'nav.fullMigrationCompleted',
      yourDemoMigrationCompleted: 'nav.yourDemoMigrationCompleted',
      migratingCompleted: 'nav.migratingCompleted',
      thisAdditionFieldIsRequired: 'nav.thisAdditionFieldIsRequired',
   },
};
