import { Stack, SvgIcon } from '@mui/material';
import React, { useRef, useState, memo } from 'react';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import DefaultGrow from '../../CustomComponents/DefaultGrow';
import { LoadingButton } from '@mui/lab';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useAuth } from 'src/hooks/use-auth';
import { demoMigrationServices } from 'src/api/services/demoMigration';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import DemoSuccessDialog from '../DemoMigration/DemoSuccessDialog';
import { useAlert } from 'src/hooks/useAlert';
import cookies from 'src/api/services/cookies';
import { wixAppServices } from 'src/api/services/wixApp';

const initialOptions = {
   'client-id':
      process.env.REACT_APP_CLIENT_PAYPAL ||
      'AQxBFK3iw8vCB83Ecp_UgolLOL8wXjAuuNYp_WwSa4li682mG36HNYgyPi_wyVUbkRHv9SpSzHh-hUSK',
};

const style = { layout: 'vertical', minHeight: 130, minWidth: '100%' };
const fppCookie = cookies.get('_fprom_tid');

function ConfirmActions(props) {
   const { loading, data, updateType, smartWarning } = props;

   const smartDisable = updateType === 'smart' && !smartWarning;

   const migrationId = data?.order?.migration_process_id;

   const { user, initialize } = useAuth();
   const isStaff = user?.user?.group === 2;

   const navigate = useNavigate();

   const alert = useAlert();

   const refSuccess = useRef(null);

   const totalAmmount = data?.order?.total;
   const neededAmount = totalAmmount - user?.user?.balance || 0;

   const [dataPayment, setDataPayment] = useState({ tracking_id: null, order_id: null });
   const [actionLoading, setActionLoading] = useState(false);
   const [currentLoading, setLoading] = useState(false);

   const loginFromWix = JSON.parse(localStorage.getItem('loginFromWix'));
   const wixUserID = localStorage.getItem('wix_user_id');

   const openDialog = (payment) => {
      refSuccess.current.showDialog(payment);
   };

   const createOrder = async (data, actions) => {
      let _payload = {
         order_type: 'basic',
         migration_id: migrationId,
         total: totalAmmount,
      };

      if (fppCookie) _payload.fppCookie = fppCookie;

      if (!dataPayment.order_id) {
         const orderData = await demoMigrationServices.post.createOrder(_payload);
         if (orderData.data.success) {
            setDataPayment(orderData.data.data.data_payment);
            return orderData.data.data.data_payment.order_id;
         }
      } else return dataPayment.order_id;
   };

   const onApprove = async (data, actions) => {
      let _payload = {
         tracking_id: data.orderID ?? dataPayment.tracking_id,
         order_id: data.orderID ?? dataPayment.order_id,
         order_type: 'basic',
      };

      if (fppCookie) _payload.fppCookie = fppCookie;

      const captureOrder = async () => {
         try {
            await demoMigrationServices.post.captureOrder(_payload).then((res) => {
               if (res.data.success) {
                  openDialog(res.data.data.order.payment);
               }
            });
            initialize();
         } catch (error) {
            console.log(error);
         }
      };
      return await captureOrder();
   };

   const getActionName = () => {
      switch (updateType) {
         case 'recent':
            return 'Start New Entities Migration';
         case 'smart':
            return 'Start Smart Update Migration';

         default:
            return '';
      }
   };

   const startFullMigration = async () => {
      setActionLoading((current) => !current);
      try {
         await demoMigrationServices.get.startFullMigration(migrationId).then((res) => {
            if (res.data.success) navigate(`/full-migration/${migrationId}`);
            else {
               const message = res.data.data?.connect?.msg || res.data?.message;
               alert('error', message);
            }
         });
      } catch (error) {
         alert('error', `${getActionName()} Fail`);
      }
      setActionLoading((current) => !current);
   };

   const wixCheckout = async () => {
      let _payload = {
         product_id: `basic-${totalAmmount}`,
         instance_id: wixUserID,
      };

      setLoading((current) => !current);
      try {
         await wixAppServices.post.checkout(_payload).then((res) => {
            if (res && res.data.success) window.location.href = res.data.data.checkoutUrl;
         });
      } catch (error) {
         alert('error', 'Place Order Fail');
         console.log(error);
      }
      setLoading((current) => !current);
   };

   const renderButton = () => {
      if (neededAmount > 0 && !isStaff)
         return (
            <Stack spacing={1.5}>
               {loginFromWix && (
                  <LoadingButton
                     variant="contained"
                     onClick={() => wixCheckout()}
                     loading={currentLoading}
                  >
                     {getActionName()} Now
                  </LoadingButton>
               )}
               {!loginFromWix && (
                  <PayPalScriptProvider options={initialOptions}>
                     <PayPalButtons
                        fundingSource={undefined}
                        forceReRender={[2, 'USD', style, totalAmmount]}
                        style={style}
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApprove={(data, actions) => onApprove(data, actions)}
                     />
                  </PayPalScriptProvider>
               )}
            </Stack>
         );
      return (
         <LoadingButton
            variant="contained"
            type="submit"
            loading={actionLoading}
            disabled={isEmpty(data) || loading || smartDisable}
            onClick={() => startFullMigration()}
            endIcon={
               <SvgIcon>
                  <ArrowRightIcon />
               </SvgIcon>
            }
         >
            {getActionName()} Now
         </LoadingButton>
      );
   };

   return (
      <React.Fragment>
         {/* Button */}
         <DefaultGrow index={4}>
            <Stack alignItems="center" spacing={1}>
               {renderButton()}
            </Stack>
         </DefaultGrow>

         {/* Dialog */}
         <DemoSuccessDialog
            loading={loading}
            ref={refSuccess}
            data={data}
            startFullMigration={startFullMigration}
         />
      </React.Fragment>
   );
}

export default memo(ConfirmActions);
