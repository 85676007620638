import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import DefaultGrow from 'src/pages/dashboard/CustomComponents/DefaultGrow';
import ConfirmOptionsTable from '../ConfirmComps/ConfirmOptionsTable';
import { useSettings } from 'src/hooks/use-settings';

function ConfirmOptions(props) {
   const { afterHover, data } = props;

   const totalOptionsPrice = data?.order?.options_price;

   const settings = useSettings();

   return (
      <React.Fragment>
         <Grid item xs={15} md={9}>
            <DefaultGrow>
               <Card sx={{ height: '100%' }} onMouseEnter={() => afterHover('additional_options')}>
                  <CardHeader
                     title={
                        <Typography variant="h5">
                           Additional Options: ${totalOptionsPrice}
                        </Typography>
                     }
                     sx={{
                        background: (theme) => {
                           return settings.paletteMode === 'light'
                              ? theme.palette.primary.light
                              : theme.palette.primary.darkest;
                        },
                     }}
                  />
                  <Divider />

                  <CardContent sx={{ padding: 1 }}>
                     <ConfirmOptionsTable data={data} />
                  </CardContent>
               </Card>
            </DefaultGrow>
         </Grid>
      </React.Fragment>
   );
}

export default ConfirmOptions;
