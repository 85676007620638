import PropTypes from 'prop-types';
import { useSettings } from 'src/hooks/use-settings';
import { useSectionsMenu } from './config';
import { HorizontalLayout } from './horizontal-layout';
import { VerticalLayout } from './vertical-layout';
import { withAuthGuard } from 'src/hocs/with-auth-guard';
import React from 'react';
import ZendeskScript from '../zendeskScript/ZendeskScript';

export const Layout = withAuthGuard((props) => {
   const settings = useSettings();
   const sections = useSectionsMenu();

   const loginFromWix = JSON.parse(localStorage.getItem('loginFromWix'));

   if (loginFromWix) {
      sections[0].items = sections[0].items?.filter(
         (item) => !['/all-in-one-migration', '/custom-migration'].includes(item.path)
      );
   }

   const renderLayout = () => {
      if (settings.layout === 'horizontal')
         return <HorizontalLayout sections={sections} navColor={settings.navColor} {...props} />;

      return <VerticalLayout sections={sections} navColor={settings.navColor} {...props} />;
   };

   return (
      <React.Fragment>
         <ZendeskScript />

         {renderLayout()}
      </React.Fragment>
   );
});

Layout.propTypes = {
   children: PropTypes.node,
};
