import { useDispatch } from "react-redux";
import { thunksAlert } from "src/thunks/toastAlertThunks";

export const useAlert = () => {
   const dispatch = useDispatch();

   const alert = (type, message) => {
      dispatch(thunksAlert.toastAlert(type));
      dispatch(thunksAlert.setAlertMessage(message));
   };

   return alert;
};
