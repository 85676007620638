import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MobileNav } from '../mobile-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';
import { useAuth } from 'src/hooks/use-auth';
import Welcome from 'src/pages/dashboard/Welcome';
import { paths } from 'src/paths';

const HorizontalLayoutRoot = styled('div')({
   display: 'flex',
   flex: '1 1 auto',
   maxWidth: '100%',
   flexDirection: 'column',
   marginTop: 61,
});

const HorizontalLayoutContainer = styled('div')({
   display: 'flex',
   flex: '1 1 auto',
   flexDirection: 'column',
   width: '100%',
});

export const HorizontalLayout = (props) => {
   const { children, navColor, sections } = props;
   const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
   const mobileNav = useMobileNav();

   const { user } = useAuth();
   const isUpdateBasic = user?.user?.is_update_cate;

   const loginFromAdmin = localStorage.getItem('loginFromAdmin');

   const renderChildren = () => {
      if (!isUpdateBasic && !loginFromAdmin) {
         window.history.replaceState(null, '', paths.dashboard.welcome);
         return <Welcome />;
      }
      return children;
   };

   return (
      <>
         <TopNav color={navColor} onMobileNav={mobileNav.handleOpen} sections={sections} />
         {!lgUp && (
            <MobileNav
               color={navColor}
               onClose={mobileNav.handleClose}
               open={mobileNav.open}
               sections={sections}
            />
         )}

         <HorizontalLayoutRoot>
            <HorizontalLayoutContainer>{renderChildren()}</HorizontalLayoutContainer>
         </HorizontalLayoutRoot>
      </>
   );
};

HorizontalLayout.propTypes = {
   children: PropTypes.node,
   navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
   sections: PropTypes.array,
};
