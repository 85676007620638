import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { CustomTableCellSmall } from 'src/pages/dashboard/CustomComponents/Custom';
import ConfirmEntitiesCheckbox from './ConfirmEntitiesCheckbox';

const header = ['Entity', 'Count', 'Select Entities'];

function ConfirmEntitiesTable(props) {
   const { data, setHasChanged, renderEntities } = props;

   const renderRow = (entity) => (
      <TableRow>
         <CustomTableCellSmall align="center" width="50%">
            {entity.name}
         </CustomTableCellSmall>
         <CustomTableCellSmall align="center" width="50%">
            {data?.order?.entities[entity.field] || 0}
         </CustomTableCellSmall>
         <CustomTableCellSmall align="center" width="50%">
            <ConfirmEntitiesCheckbox
               sx={{ padding: 0 }}
               name={entity.field}
               setHasChanged={setHasChanged}
               data={data}
            />
         </CustomTableCellSmall>
      </TableRow>
   );

   return (
      <Table>
         <TableHead>
            <TableRow>
               {header.map((head) => (
                  <CustomTableCellSmall key={head} align="center">
                     {head}
                  </CustomTableCellSmall>
               ))}
            </TableRow>
         </TableHead>
         <TableBody>
            {renderEntities?.map((entity) => (
               <React.Fragment key={entity.field}>{renderRow(entity)}</React.Fragment>
            ))}
         </TableBody>
      </Table>
   );
}

export default ConfirmEntitiesTable;
