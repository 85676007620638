import axios from 'src/utils/instantAxios.js';

export const accountServices = {
   post: {
      login: (payload) =>
         axios.post('/api/auth/login', {
            ...payload,
         }),
      register: (payload) =>
         axios.post('/api/auth/register', {
            ...payload,
         }),
      logout: () => axios.post('/api/auth/logout'),
      createTopUp: (payload) =>
         axios.post('/api/paypal/top-up-balance/create-order', { ...payload }),
      captureTopUp: (payload) =>
         axios.post('/api/paypal/top-up-balance/capture-order', { ...payload }),
      forgotPassword: (payload) => axios.post('api/auth/forgot-password', { ...payload }),
      resetPassword: (payload) => axios.post('api/auth/reset-password', { ...payload }),
      customerUpdate: (payload) => axios.post('api/profile/customer_update', { ...payload }),
      confirmSLA: (payload) => axios.post('api/profile/sla/confirm', { ...payload }),
      updateSLA: (payload) => axios.post('api/profile/sla/update', { ...payload }),
   },
   get: {
      loginByToken: (payload) =>
         axios.get('/api/auth/login-by-token', {
            params: { ...payload },
         }),
      resetPassword: (payload) => axios.get(`api/auth/reset-password-user/${payload}`),
      socialLogin: (payload) => axios.get(`/api/auth/redirect/${payload}`),
      socialCallBack: (payload) =>
         axios.get(`/api/auth/loginSocial/${payload.type}`, {
            params: { ...payload.params },
         }),
      verifyEmail: (payload) => axios.get(`api/customers/verify-email`, { params: { ...payload } }),
      loginSso: (payload) => axios.get(`api/sso/login`, { params: { ...payload } }),
      getEmployees: () => axios.get('api/profile/sla/user-roles'),
   },
};
