import { Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import React, { memo } from 'react';
import DefaultGrow from '../../CustomComponents/DefaultGrow';

function DemoInclude(props) {
   const { data, afterHover } = props;

   return (
      <React.Fragment>
         <DefaultGrow index={3}>
            <Card onMouseEnter={() => afterHover('free_recent_data')}>
               <CardHeader title="Included In This Package" />
               <Divider />
               <CardContent>
                  <Stack spacing={2}>
                     <Typography>
                        Free & Unlimited Re-Migration, Recent Migration, Smart Update until:{' '}
                        <span style={{ fontWeight: 600 }}>{data?.migration_expired}</span>
                     </Typography>
                     <Typography fontStyle="italic" style={{ margin: 0, fontSize: 14 }}>
                        *Only applies if the number of entities is less than 10% of your initial
                        migration
                     </Typography>
                     <Typography>
                        Priority Support: <span style={{ fontWeight: 600 }}>YES</span>
                     </Typography>
                  </Stack>
               </CardContent>
            </Card>
         </DefaultGrow>
      </React.Fragment>
   );
}

export default memo(DemoInclude);
