import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function PrepareIcon(props) {
   const { migrationAction, isFinish } = props;

   if (['migration', 'finish_migration'].includes(migrationAction) || isFinish)
      return <CheckCircleIcon color='primary' />;

   if (
      ['storage_data', 'clear_demo', 'prepare_migration', 'display_import', 'clear'].includes(
         migrationAction
      )
   )
      return <PlayCircleOutlineIcon color='primary' />;
      
   return <RadioButtonUncheckedIcon color='primary' />;
}

export default PrepareIcon;
