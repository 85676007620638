import PropTypes from 'prop-types';
// import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import { Stack } from '@mui/material';
// import { usePopover } from 'src/hooks/use-popover';
// import { TenantPopover } from './tenant-popover';

// const tenants = ['Devias', 'Acme Corp'];

export const TenantSwitch = (props) => {
   // const popover = usePopover();

   return (
      <>
         <Stack alignItems='center' direction='row' spacing={2} {...props}>
            {/* <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <SvgIcon sx={{ fontSize: 16 }}>
            <ChevronDownIcon />
          </SvgIcon>
        </IconButton> */}
         </Stack>
         {/* <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={tenants}
      /> */}
      </>
   );
};

TenantSwitch.propTypes = {
   sx: PropTypes.object,
};
