import axios from 'src/utils/instantAxios.js';

export const wixAppServices = {
   get: {
      getToken: (payload) =>
         axios.get('/api/wix/token', {
            params: { ...payload },
         }),
      getUrl: (payload) =>
         axios.get('/api/wix/app', {
            params: { ...payload },
         }),
   },
   post: {
      checkout: (payload) =>
         axios.post('/api/wix/payment', {
            ...payload,
         }),
      upgrade: (payload) =>
         axios.post('/api/wix/user-info', {
            ...payload,
         }),
   },
};
