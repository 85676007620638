import React from 'react';
import { useStepperBackground } from '../../../../hooks/useStepperBackground';
import { useSettings } from 'src/hooks/use-settings';
import { CustomGridItem } from '../../CustomComponents/Custom';
import { useMediaQuery } from '@mui/material';
import MigrationStep from '../../Stepper/MigrationStep';

function CustomStepper(props) {
   const { index, stepType = '' } = props;

   const settings = useSettings();

   const backgroundStepper = useStepperBackground(settings);
   const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

   return (
      <CustomGridItem
         item
         xs={12}
         sx={{
            position: 'sticky',
            top: settings.layout === 'horizontal' && lgUp ? '60px' : '40px',
            background: backgroundStepper,
            zIndex: '10',
         }}
      >
         <MigrationStep index={index} stepType={stepType}/>
      </CustomGridItem>
   );
}

export default CustomStepper;
