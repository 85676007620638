import { Grow } from '@mui/material';
import React from 'react';

function DefaultGrow(props) {
   const {
      children,
      onMount = true,
      easing = { enter: 'ease-out', exit: 'ease-in' },
      timeout = 500,
      unmountOnExit,
      index = 0,
      transition = 100,
      appear = true,
   } = props;
   return (
      <Grow
         in={onMount}
         unmountOnExit={unmountOnExit}
         easing={easing}
         timeout={timeout + index * transition}
         appear={appear}
      >
         {children}
      </Grow>
   );
}

export default DefaultGrow;
