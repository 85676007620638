import { slice } from 'src/slices/toastAlertSlices';

const toastAlert = (type) => async (dispatch) => {
   switch (type) {
      case 'success':
         dispatch(slice.actions.setSuccessAlert(true));
         break;
      case 'error':
         dispatch(slice.actions.setErrorAlert(true));
         break;
      case 'info':
         dispatch(slice.actions.setInforAlert(true));
         break;
      case 'warning':
         dispatch(slice.actions.setWarningAlert(true));
         break;

      default:
         break;
   }
};

const closeAlert = (type) => async (dispatch) => {
   switch (type) {
      case 'success':
         dispatch(slice.actions.setSuccessAlert(false));
         break;
      case 'error':
         dispatch(slice.actions.setErrorAlert(false));
         break;
      case 'info':
         dispatch(slice.actions.setInforAlert(false));
         break;
      case 'warning':
         dispatch(slice.actions.setWarningAlert(false));
         break;

      default:
         break;
   }
};

const setAlertMessage = (params) => async (dispatch) => {
   dispatch(slice.actions.setMessageAlert(params));
};

export const thunksAlert = {
   toastAlert,
   closeAlert,
   setAlertMessage,
};
