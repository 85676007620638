import { combineReducers } from '@reduxjs/toolkit';
import { reducer as routerTabReducer } from 'src/slices/routerTabSlices';
import { reducer as toastAlertReducer } from 'src/slices/toastAlertSlices';
import { reducer as globalLoadingReducer } from 'src/slices/globalLoadingSlices';
import { reducer as globalOnFieldReducer } from 'src/slices/globalOnField';

export const rootReducer = combineReducers({
   routerTab: routerTabReducer,
   toastAlert: toastAlertReducer,
   globalLoading: globalLoadingReducer,
   globalOnField: globalOnFieldReducer,
});
