import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from 'src/hooks/use-auth';

const ZendeskScript = memo(function ZendeskScript() {
   const { user } = useAuth();

   return (
      <Helmet>
         <script type="text/javascript">
            {user?.user &&
               window.zE &&
               window.zE(function () {
                  window.zE.identify({
                     name: user?.user?.name || '',
                     email: user?.user?.email || '',
                  });
               })}
         </script>
      </Helmet>
   );
});

export default ZendeskScript;
