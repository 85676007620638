import { Alert, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CustomSnackBar from 'src/pages/dashboard/CustomComponents/CustomSnackBar';
import { thunksAlert } from 'src/thunks/toastAlertThunks';

const alerts = ['success', 'error', 'info', 'warning'];

function ToastAlert() {
   const { errorAlert, warningAlert, successAlert, inforAlert, messageAlert } = useSelector(
      (state) => state.toastAlert
   );
   const dispatch = useDispatch();

   const closeAlert = (type) => {
      dispatch(thunksAlert.closeAlert(type));
      setTimeout(() => {
         dispatch(thunksAlert.setAlertMessage(''));
      }, 500);
   };

   const getAlertType = (type) => {
      switch (type) {
         case 'success':
            return successAlert;
         case 'error':
            return errorAlert;
         case 'info':
            return inforAlert;
         case 'warning':
            return warningAlert;

         default:
            break;
      }
   };

   return (
      <React.Fragment>
         {alerts.map((alert) => (
            <CustomSnackBar
               key={alert}
               open={getAlertType(alert)}
               onClose={() => closeAlert(alert)}
            >
               <Alert variant="filled" severity={alert} onClose={() => closeAlert(alert)}>
                  <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: messageAlert }}/>
               </Alert>
            </CustomSnackBar>
         ))}
      </React.Fragment>
   );
}

export default ToastAlert;
