import { LoadingButton } from '@mui/lab';
import { Stack, SvgIcon, Tooltip, Typography, Zoom } from '@mui/material';
import InfoCircleIcon from '@untitled-ui/icons-react/build/esm/InfoCircle';
import React from 'react';

function ConfirmUpdateButton(props) {
   const { hasChanged, loading } = props;

   return (
      <Zoom easing={{ enter: 'ease-out', exit: 'ease-in' }} in={hasChanged}>
         <Stack
            alignItems="center"
            direction="row"
            display="flex"
            justifyContent="center"
            sx={{ mt: 1 }}
         >
            <LoadingButton variant="contained" size="small" type="submit" loading={loading}>
               Update
            </LoadingButton>

            <Tooltip
               title={
                  <Typography variant="p">
                     If you changed the recent entities, you must click update to recalculate the
                     order
                  </Typography>
               }
            >
               <SvgIcon color="warning" sx={{ ml: '5px' }}>
                  <InfoCircleIcon />
               </SvgIcon>
            </Tooltip>
         </Stack>
      </Zoom>
   );
}

export default ConfirmUpdateButton;
