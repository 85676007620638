import { CardHeader, SvgIcon, Typography } from '@mui/material';
import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useSettings } from 'src/hooks/use-settings';
import { darkWarningColor, lightWarningColor } from 'src/locales/constant/constants';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';

function CustomCardWarningHeader(props) {
   const { content = tokens.nav.warning } = props;

   const settings = useSettings();
   const { t } = useTranslation();

   return (
      <CardHeader
         title={
            <Typography variant="h5" display="flex" alignItems="center">
               <SvgIcon color="warning">
                  <WarningAmberIcon />
               </SvgIcon>
               {t(content)}
            </Typography>
         }
         sx={{
            background: settings.paletteMode === 'light' ? lightWarningColor : darkWarningColor,
            padding: '13px 16px',
         }}
      />
   );
}

export default CustomCardWarningHeader;
