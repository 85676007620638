import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   globalLoading: false,
};

const reducers = {
   setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
   },
};

export const slice = createSlice({
   name: 'globalLoading',
   initialState,
   reducers,
});

export const { reducer } = slice;
