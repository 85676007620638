import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MobileNav } from '../mobile-nav';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';
import Welcome from 'src/pages/dashboard/Welcome';
import { useAuth } from 'src/hooks/use-auth';
import { paths } from 'src/paths';

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled('div')(({ theme }) => ({
   display: 'flex',
   flex: '1 1 auto',
   maxWidth: '100%',
   flexDirection: 'column',
   [theme.breakpoints.up('lg')]: {
      paddingLeft: SIDE_NAV_WIDTH,
   },
}));

const VerticalLayoutContainer = styled('div')({
   display: 'flex',
   flex: '1 1 auto',
   flexDirection: 'column',
   width: '100%',
});

export const VerticalLayout = (props) => {
   const { children, sections, navColor } = props;
   const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
   const mobileNav = useMobileNav();

   const { user } = useAuth();
   const isUpdateBasic = user?.user?.is_update_cate;
   const loginFromAdmin = localStorage.getItem('loginFromAdmin');

   const renderChildren = () => {
      if (!isUpdateBasic && !loginFromAdmin) {
         window.history.replaceState(null, '', paths.dashboard.welcome)
         return <Welcome />;
      }
      return children;
   };

   return (
      <>
         <TopNav onMobileNavOpen={mobileNav.handleOpen} />
         {lgUp && <SideNav color={navColor} sections={sections} />}
         {!lgUp && (
            <MobileNav
               color={navColor}
               onClose={mobileNav.handleClose}
               open={mobileNav.open}
               sections={sections}
            />
         )}

         <VerticalLayoutRoot>
            <VerticalLayoutContainer>{renderChildren()}</VerticalLayoutContainer>
         </VerticalLayoutRoot>
      </>
   );
};

VerticalLayout.propTypes = {
   children: PropTypes.node,
   navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
   sections: PropTypes.array,
};
