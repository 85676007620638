import { Outlet } from 'react-router-dom';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { shopifyRoutes } from './shopifyRoutes';
import { lazy } from 'react';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';
const HomePage = lazy(() => import('src/pages/dashboard/index'));
const LoginByToken = lazy(() => import('src/pages/dashboard/LoginByToken'));
const ShopifyCallBack = lazy(() => import('src/pages/ShopifyCallBack'));
const ShopifyRedirect = lazy(() => import('src/pages/ShopifyRedirect'));
const ShopifyPayment = lazy(() => import('src/pages/ShopifyPayment'));
const ShoplineRedirect = lazy(() => import('src/pages/ShoplineRedirect'));
const ShoplineCallBack = lazy(() => import('src/pages/ShoplineCallBack'));
const WixRedirect = lazy(() => import('src/pages/WixRedirect'));
const WixCallBack = lazy(() => import('src/pages/WixCallBack'));
const WixUpgrade = lazy(() => import('src/pages/WixUpgrade'));
const VerifyEmail = lazy(() => import('src/pages/VerifyEmail'));
const SquareUpCallback = lazy(() => import('src/pages/SquareUpCallback'));
const BigCommerceAuthorize = lazy(() => import('src/pages/BigCommerceAuthorize'));
const CafeRedirect = lazy(() => import('src/pages/CafeRedirect'));
const CafeCallBack = lazy(() => import('src/pages/CafeCallBack'));

export const routes = [
   {
      element: <Outlet />,
      children: [
         {
            index: true,
            element: (
               <DashboardLayout>
                  <HomePage />
               </DashboardLayout>
            ),
         },
      ],
   },
   ...authDemoRoutes,
   ...dashboardRoutes,
   ...shopifyRoutes,
   {
      path: '401',
      element: <Error401Page />,
   },
   {
      path: '404',
      element: <Error404Page />,
   },
   {
      path: '500',
      element: <Error500Page />,
   },
   {
      path: '*',
      element: <Error404Page />,
   },
   {
      path: 'login-by-token',
      element: <LoginByToken />,
   },
   {
      path: 'api/shopify/app',
      element: <ShopifyCallBack />,
   },
   {
      path: 'api/shopify/app/:server_id',
      element: <ShopifyCallBack />,
   },
   {
      path: 'merchant/shopify/callback',
      element: <ShopifyRedirect />,
   },
   {
      path: 'merchant/shopify/callback/:server_id',
      element: <ShopifyRedirect />,
   },
   {
      path: 'merchant/shopify/payment',
      element: <ShopifyPayment />,
   },
   {
      path: 'merchant/shopify/payment/:server_id/:migration_id',
      element: <ShopifyPayment />,
   },
   {
      path: 'api/shopline/app',
      element: <ShoplineCallBack />,
   },
   {
      path: 'api/shopline/app/:server_id',
      element: <ShoplineCallBack />,
   },
   {
      path: 'merchant/shopline/callback',
      element: <ShoplineRedirect />,
   },
   {
      path: 'merchant/shopline/callback/:server_id',
      element: <ShoplineRedirect />,
   },
   {
      path: 'api/wix/app',
      element: <WixCallBack />,
   },
   {
      path: 'api/wix/app/:server_id',
      element: <WixCallBack />,
   },
   {
      path: 'merchant/wix/callback',
      element: <WixRedirect />,
   },
   {
      path: 'merchant/wix/callback/:server_id',
      element: <WixRedirect />,
   },
   {
      path: 'wix-pricing',
      element: <WixUpgrade />,
   },
   {
      path: 'verify_email',
      element: <VerifyEmail />,
   },
   {
      path: '/api/squareup/authorize',
      element: <SquareUpCallback />,
   },
   {
      path: '/api/bigcommerce/authorize',
      element: <BigCommerceAuthorize />,
   },
   {
      path: '/api/cafe24/redirect',
      element: <CafeRedirect />,
   },
   {
      path: '/api/cafe24/callback',
      element: <CafeCallBack />,
   },
];
