import { Box } from '@mui/material';

export const SplashScreen = () => (
   <Box
      sx={{
         alignItems: 'center',
         backgroundColor: 'background.paper',
         display: 'flex',
         flexDirection: 'column',
         height: '100vh',
         justifyContent: 'center',
         left: 0,
         p: 3,
         position: 'fixed',
         top: 0,
         width: '100vw',
         zIndex: 1400,
      }}
   >
      <Box
         sx={{
            display: 'inline-flex',
            height: 60,
            width: 60,
         }}
      >
         <img src='/assets/logo_lit_wo_text.png' style={{ width: '100%' }} />
      </Box>
   </Box>
);
