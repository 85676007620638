import { useField } from 'formik';
import React from 'react';
import { countries } from 'src/locales/countries/countries';
import { CustomAutoComplete, CustomPopper } from '../CustomComponents/Custom';
import { TextField } from '@mui/material';

const options = Object.keys(countries).map((code) => ({
   code: code,
   name: countries[code],
}));

function Country() {
   const [{ value: country }, { error, touched }, { setValue: setCountry }] = useField('country');

   const applyChange = (newVal) => {
      if (newVal?.code) setCountry(newVal?.code);
      else setCountry('');
   };

   const getOptions = () => options.find((option) => option.code === country) || null;

   return (
      <CustomAutoComplete
         label="Country"
         value={getOptions()}
         PopperComponent={CustomPopper}
         getOptionLabel={(option) => option.name}
         fullWidth
         name={'country'}
         options={options}
         onChange={(event, newVal) => applyChange(newVal)}
         renderInput={(params) => (
            <TextField
               {...params}
               fullWidth
               error={touched && error ? error : null}
               helperText={touched && error ? error : null}
               name="Country"
               label={
                  <label>
                     Country<span style={{ color: 'red' }}>*</span>
                  </label>
               }
            />
         )}
      />
   );
}

export default Country;
