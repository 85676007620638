import { createResourceId } from 'src/utils/create-resource-id';
import { JWT_EXPIRES_IN, JWT_SECRET, sign } from 'src/utils/jwt';
import { wait } from 'src/utils/wait';
import { accountServices } from '../services/accounts';
import cookies from '../services/cookies';
import { profileServices } from '../services/profile';
import instance from 'src/utils/instantAxios';

const STORAGE_KEY = 'users';

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = () => {
   try {
      // const data = sessionStorage.getItem(STORAGE_KEY);
      const data = cookies.get(STORAGE_KEY);

      if (!data) {
         return [];
      }

      return data;
   } catch (err) {
      console.error(err);
      return [];
   }
};

const persistUser = (user) => {
   try {
      const users = getPersistedUsers();

      const _user = users.map((us) => us.id);

      if (_user.includes(user.id)) return;

      const data = JSON.stringify([...users, user]);
      cookies.set(STORAGE_KEY, data);
   } catch (err) {
      console.error(err);
   }
};

class AuthApi {
   async signIn(request) {
      await wait(500);

      return new Promise(async (resolve, reject) => {
         try {
            await accountServices.post.login(request).then(async (res) => {
               if (res.data.success) {
                  const access_token = res.data.data.token;
                  cookies.set('access_token', access_token, { path: '/' });
                  resolve({ accessToken: access_token });
               } else{
                  resolve({ message: res.data.message });
               }
            });
         } catch (err) {
            console.error('[Auth Api]: ', err);
            reject(new Error('Internal server error'));
         }
      });
   }

   async signUp(request) {
      const { email, name, password } = request;

      await wait(1000);

      return new Promise((resolve, reject) => {
         try {
            // Merge static users (data file) with persisted users (browser storage)
            const mergedUsers = [...getPersistedUsers()];

            // Check if a user already exists
            let user = mergedUsers.find((user) => user.email === email);

            if (user) {
               reject(new Error('User already exists'));
               return;
            }

            user = {
               id: createResourceId(),
               avatar: undefined,
               email,
               name,
               password,
               plan: 'Standard',
            };

            persistUser(user);

            const accessToken = sign({ userId: user.id }, JWT_SECRET, {
               expiresIn: JWT_EXPIRES_IN,
            });

            resolve({ accessToken });
         } catch (err) {
            console.error('[Auth Api]: ', err);
            reject(new Error('Internal server error'));
         }
      });
   }

   me(request) {
      return new Promise(async (resolve, reject) => {
         try {
            // Check if there is a accessToken and set it to header, this is for everytime we refresh the page
            instance.defaults.headers.common.Authorization = `Bearer ${request.accessToken}`;

            const resProfile = await profileServices.get.getProfileInformation();

            const user = resProfile.data;

            if (!user) {
               reject(new Error('Invalid authorization token'));
               return;
            }

            resolve(user);
         } catch (err) {
            console.error('[Auth Api]: ', err);
            reject(new Error('Internal server error'));
         }
      });
   }
}

export const authApi = new AuthApi();
