export const paths = {
   index: '/',
   checkout: '/checkout',
   contact: '/contact',
   pricing: '/pricing',
   auth: {
      jwt: {
         login: '/login',
         register: '/register',
      },
   },
   authDemo: {
      forgotPassword: {
         modern: '/forgot-password',
      },
      login: {
         modern: '/auth-demo/login/modern',
      },
      register: {
         modern: '/register',
      },
   },
   dashboard: {
      index: '/',
      academy: {
         index: '/dashboard/academy',
         courseDetails: '/dashboard/academy/courses/:courseId',
      },
      account: '/account',
      profile: '/profile',
      payment: '/payment',
      orderHistory: '/orderHistory',
      createMigration: '/create-migration',
      configuration: '/configuration',
      migration: '/migration',
      runDemo: '/demo-running',
      fullMigration: '/full-migration',
      completeMigration: '/complete-migration',
      confirmRecent: '/confirm-recent',
      confirmSmart: '/confirm-smart',
      migrationDetail: '/migration-detail',
      allInOne: '/all-in-one-migration',
      allInOneIntro: '/all-in-one-intro',
      customMigration: '/custom-migration',
      confirmCustomMigration: '/confirm-custom-migration',
      confirmOrder: '/confirm-order',
      checkout: '/check-out',
      confirmCheckout: '/confirm-check-out',
      welcome: '/welcome',
      specialized: '/specialized-migration',
      subscription: '/premium-subscription',
   },
   docs: 'https://material-kit-pro-react-docs.devias.io',
   401: '/401',
   404: '/404',
   500: '/500',
};
