import { cloneDeep } from 'lodash';
import { useState, useEffect } from 'react';
import { instructionServices } from 'src/api/services/instruction';

const defaultHeaderWidth = {
   image: { width: '1.5rem', marginRight: '.75rem' },
   padding: '16px 24px',
};

export const useInstruction = (smallestPoint, instructionState = []) => {
   const [headerWidth, setHeaderWidth] = useState(defaultHeaderWidth);
   const [instructions, setInstructions] = useState(instructionState);

   useEffect(() => {
      const handleScroll = () => {
         let _smallestPoint = smallestPoint ?? 70;
         let _headerWidth = cloneDeep(headerWidth);
         let _scrollHeight = window.scrollY;

         if (window.scrollY <= _smallestPoint) {
            _headerWidth.image.width = `${1.5 - (_scrollHeight / _smallestPoint) * 0.5}rem`;
            _headerWidth.image.marginRight = `${
               0.75 - (_scrollHeight / _smallestPoint) * 0.375
            }rem`;
            _headerWidth.padding = `${16 - (_scrollHeight / _smallestPoint) * 8}px 24px ${
               16 - (_scrollHeight / _smallestPoint) * 8
            }px`;
            setHeaderWidth(() => _headerWidth);
         }
      };

      window.onscroll = handleScroll;
   }, [smallestPoint, headerWidth]);

   useEffect(() => {
      const getInstruction = async () => {
         await instructionServices.getInstruction().then((res) => {
            if (res.data.success) {
               let _res = cloneDeep(res.data.data);
               _res.forEach((element) => {
                  const _newContent = element.content
                     .replaceAll('li>', 'p>')
                     .replace('<ul>', '')
                     .replace('</ul>', '');
                  element.content = _newContent;
               });
               setInstructions(() => _res);
            }
         });
      };

      if (instructionState.length === 0) getInstruction();
      // eslint-disable-next-line
   }, []);

   return {
      headerWidth,
      instructions,
   };
};
