import { useSettings } from 'src/hooks/use-settings';

const useStepperBackground = () => {
   const settings = useSettings();
   let stepperBackground = '';

   if (settings.paletteMode === 'light')
      stepperBackground = settings.contrast === 'high' ? '#f8f9fa' : '#ffffff';

   if (settings.paletteMode === 'dark')
      stepperBackground = settings.contrast === 'high' ? '#0b0f19' : '#0e1320';

   return stepperBackground;
};

export { useStepperBackground };
