import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { rootColorOptions } from 'src/locales/constant/rootColorOptions';

const STORAGE_KEY = 'app.settings';

const restoreSettings = () => {
   let value = null;

   try {
      const restored = window.localStorage.getItem(STORAGE_KEY);

      if (restored) {
         value = JSON.parse(restored);
      }
   } catch (err) {
      console.error(err);
      // If stored data is not a strigified JSON this will fail,
      // that's why we catch the error
   }

   return value;
};

const deleteSettings = () => {
   try {
      window.localStorage.removeItem(STORAGE_KEY);
   } catch (err) {
      console.error(err);
   }
};

const storeSettings = (value) => {
   try {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
   } catch (err) {
      console.error(err);
   }
};

const initialSettings = {
   colorPreset: 'blue',
   contrast: 'high',
   direction: 'ltr',
   layout: 'horizontal',
   navColor: 'evident',
   paletteMode: 'light', //we no longer change the pallete Mode anymore, but let's just keep it here
   language: 'en',
   responsiveFontSizes: true,
   stretch: false,
};

const initialState = {
   ...initialSettings,
   isInitialized: false,
   openDrawer: false,
};

export const SettingsContext = createContext({
   ...initialState,
   handleDrawerClose: () => {},
   handleDrawerOpen: () => {},
   handleReset: () => {},
   handleUpdate: () => {},
   isCustom: false,
});

export const SettingsProvider = (props) => {
   const { children } = props;
   const [state, setState] = useState(initialState);

   const root = document.querySelector(':root');

   useEffect(() => {
      const option = rootColorOptions.find((option) => option.value === state.colorPreset);

      root.style.setProperty('--main-color', option.color);
      root.style.setProperty('--light-color', option.lightColor);
      root.style.setProperty('--dark-color', option.darkColor);
      root.style.setProperty('--darkest-color', option.darkestColor);
      // eslint-disable-next-line
   }, [state.colorPreset]);

   useEffect(() => {
      const restored = restoreSettings();

      if (restored) {
         setState((prevState) => ({
            ...prevState,
            ...restored,
            isInitialized: true,
         }));
      }
   }, []);

   const handleReset = useCallback(() => {
      deleteSettings();
      setState((prevState) => ({
         ...prevState,
         ...initialSettings,
      }));
   }, []);

   const handleUpdate = useCallback((settings) => {
      setState((prevState) => {
         storeSettings({
            colorPreset: prevState.colorPreset,
            contrast: prevState.contrast,
            direction: prevState.direction,
            layout: prevState.layout,
            navColor: prevState.navColor,
            paletteMode: prevState.paletteMode,
            language: prevState.language,
            responsiveFontSizes: prevState.responsiveFontSizes,
            stretch: prevState.stretch,
            ...settings,
         });

         return {
            ...prevState,
            ...settings,
         };
      });
   }, []);

   const handleDrawerOpen = useCallback(() => {
      setState((prevState) => ({
         ...prevState,
         openDrawer: true,
      }));
   }, []);

   const handleDrawerClose = useCallback(() => {
      setState((prevState) => ({
         ...prevState,
         openDrawer: false,
      }));
   }, []);

   const isCustom = useMemo(() => {
      return !isEqual(initialSettings, {
         colorPreset: state.colorPreset,
         contrast: state.contrast,
         direction: state.direction,
         layout: state.layout,
         navColor: state.navColor,
         paletteMode: state.paletteMode,
         language: state.language,
         responsiveFontSizes: state.responsiveFontSizes,
         stretch: state.stretch,
      });
   }, [state]);

   return (
      <SettingsContext.Provider
         value={{
            ...state,
            handleDrawerClose,
            handleDrawerOpen,
            handleReset,
            handleUpdate,
            isCustom,
         }}
      >
         {children}
      </SettingsContext.Provider>
   );
};

SettingsProvider.propTypes = {
   children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;
