import {
   Button,
   Card,
   CardContent,
   CardHeader,
   Divider,
   IconButton,
   Stack,
   Tooltip,
   Typography,
   Zoom,
} from '@mui/material';
import React, { useState, memo } from 'react';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { CustomInput } from '../../CustomComponents/Custom';
import { demoMigrationServices } from 'src/api/services/demoMigration';
import { LoadingButton } from '@mui/lab';
import { useAlert } from 'src/hooks/useAlert';

function DemoCoupon(props) {
   const { migrationId, afterApplyCoupon, loading } = props;

   const alert = useAlert();

   const [showDiscount, setShowDiscount] = useState(false);
   const [couponCode, setCouponCode] = useState(null);
   const [applying, setApplying] = useState(false);
   const [error, setError] = useState('');

   const applyChange = (value) => {
      setCouponCode(() => value);
      if (!value) setError(() => '');
   };

   const applyCoupon = async () => {
      let _payload = {
         coupon_code: couponCode,
         migration_id: migrationId,
      };
      setError(() => '');
      setApplying((current) => !current);
      try {
         await demoMigrationServices.get.applyCoupon(_payload).then((res) => {
            if (res.data.success || res.data.result === 'success') {
               afterApplyCoupon(res.data.data);
               alert('success', res.data.message || res.data.msg);
            } else {
               alert('error', res.data.message || res.data.msg);
               setError(() => res.data.message || res.data.msg);
            }
         });
      } catch (error) {
         console.log(error);
         alert('error', 'Applying Coupon Fail');
      }
      setApplying((current) => !current);
   };

   return (
      <React.Fragment>
         {!showDiscount && (
            <Zoom
               easing={{ enter: 'ease-out', exit: 'ease-in' }}
               in={!showDiscount}
               unmountOnExit={showDiscount}
            >
               <Stack alignItems="center">
                  <Button
                     disabled={loading}
                     variant="contained"
                     onClick={(e) => {
                        e.preventDefault();
                        setShowDiscount((current) => !current);
                     }}
                     size="small"
                  >
                     I Have Coupon
                  </Button>
               </Stack>
            </Zoom>
         )}
         {showDiscount && (
            <Zoom
               easing={{ enter: 'ease-out', exit: 'ease-in' }}
               in={showDiscount}
               unmountOnExit={!showDiscount}
            >
               <Card>
                  <CardHeader
                     title={
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                           <Typography variant="5">Discount</Typography>
                           <Tooltip title="Hide">
                              <IconButton
                                 aria-label="close"
                                 onClick={() => setShowDiscount((current) => !current)}
                                 sx={{
                                    color: (theme) => theme.palette.grey[500],
                                    padding: 0,
                                    width: 20,
                                    height: 20,
                                 }}
                              >
                                 <XIcon />
                              </IconButton>
                           </Tooltip>
                        </Stack>
                     }
                  />
                  <Divider />
                  <CardContent>
                     <Stack direction="row" alignItems="center" spacing={3}>
                        <CustomInput
                           label="Discount Code"
                           sx={{ width: '50%' }}
                           error={error}
                           onChange={(event) => applyChange(event.target.value)}
                        />
                        <LoadingButton
                           loading={applying}
                           size="small"
                           disabled={!couponCode || loading}
                           variant="contained"
                           onClick={() => applyCoupon()}
                        >
                           Apply
                        </LoadingButton>
                     </Stack>
                     <Typography variant="subtitle2" sx={{ color: 'red' }}>
                        {error}
                     </Typography>
                  </CardContent>
               </Card>
            </Zoom>
         )}
      </React.Fragment>
   );
}

export default memo(DemoCoupon);
