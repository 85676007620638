import React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { CustomTableCellSmall } from 'src/pages/dashboard/CustomComponents/Custom';
import { entities } from 'src/locales/constant/arrayRender';

function ConfirmUpdatedTable(props) {
   const { entitiesUpdate } = props;

   const renderUpdateEntities = entities.filter((entity) =>
      Object.keys(entitiesUpdate).includes(entity.field)
   );
   
   return (
      <Table>
         <TableHead>
            <TableRow>
               {['entity', 'count'].map((head) => (
                  <CustomTableCellSmall key={head} align="center">
                     {head}
                  </CustomTableCellSmall>
               ))}
            </TableRow>
         </TableHead>
         <TableBody>
            {renderUpdateEntities?.map((entity) => (
               <TableRow key={entity.field}>
                  <CustomTableCellSmall align="center" width="50%">
                     {entity.name}
                  </CustomTableCellSmall>
                  <CustomTableCellSmall align="center" width="50%">
                     {entitiesUpdate[entity.field] || 0}
                  </CustomTableCellSmall>
               </TableRow>
            ))}
         </TableBody>
      </Table>
   );
}

export default ConfirmUpdatedTable;
