import { blue, green, indigo, purple, orange } from 'src/theme/colors';

export const rootColorOptions = [
   {
      label: 'Green',
      value: 'green',
      color: green.main,
      lightColor: green.light,
      darkColor: green.dark,
      darkestColor: green.darkest,
   },
   {
      label: 'Blue',
      value: 'blue',
      color: blue.main,
      lightColor: blue.light,
      darkColor: blue.dark,
      darkestColor: blue.darkest,
   },
   {
      label: 'Indigo',
      value: 'indigo',
      color: indigo.main,
      lightColor: indigo.light,
      darkColor: indigo.dark,
      darkestColor: indigo.darkest,
   },
   {
      label: 'Purple',
      value: 'purple',
      color: purple.main,
      lightColor: purple.light,
      darkColor: purple.dark,
      darkestColor: purple.darkest,
   },
   {
      label: 'Orange',
      value: 'orange',
      color: orange.main,
      lightColor: orange.light,
      darkColor: orange.dark,
      darkestColor: orange.darkest,
   },
];
