import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

export const Seo = (props) => {
   const { title } = props;

   return (
      <Helmet>
         <title>{title}</title>
      </Helmet>
   );
};

Seo.propTypes = {
   title: PropTypes.string,
};
