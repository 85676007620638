import React from 'react';
import DefaultGrow from '../../CustomComponents/DefaultGrow';
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import ConfirmEntities from './ConfirmDetail/ConfirmEntities';
import ConfirmOptions from './ConfirmDetail/ConfirmOptions';
import { useSettings } from 'src/hooks/use-settings';
import { success } from 'src/theme/colors';

function ConfirmDetail(props) {
   const { afterHover, data, getOrderMigration } = props;

   const totalPrice = data?.order?.total || 0;

   const settings = useSettings();

   const getPrice = () => {
      if (totalPrice === 0) return <span style={{ color: success.main }}>FREE</span>;
      return `$${totalPrice}`;
   };

   return (
      <React.Fragment>
         <DefaultGrow>
            <Card>
               <CardHeader title="The Entities To Be Migrated & Selected Additional Options" />
               <Divider />

               <CardContent>
                  <Grid container columns={15} spacing={2}>
                     {/* Entities */}
                     <ConfirmEntities
                        afterHover={afterHover}
                        data={data}
                        getOrderMigration={getOrderMigration}
                     />

                     {/* Options */}
                     <ConfirmOptions afterHover={afterHover} data={data} />

                     {/* Total */}
                     <Grid item xs={15}>
                        <Card
                           sx={{
                              padding: 2,
                              paddingLeft: 3,
                              background: (theme) => {
                                 return settings.paletteMode === 'light'
                                    ? theme.palette.primary.light
                                    : theme.palette.primary.darkest;
                              },
                           }}
                           onMouseEnter={() => afterHover('total')}
                        >
                           <Typography variant="h6">Total: {getPrice()}</Typography>
                        </Card>
                     </Grid>
                  </Grid>
               </CardContent>
            </Card>
         </DefaultGrow>
      </React.Fragment>
   );
}

export default ConfirmDetail;
