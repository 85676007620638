import { Page } from '@shopify/polaris';
import React, { useEffect, useRef } from 'react';
import createApp from '@shopify/app-bridge';
import cookies from 'src/api/services/cookies';
import instance from 'src/utils/instantAxios';
import { getSessionToken as getShopifyToken } from '@shopify/app-bridge/utilities';
import ZendeskScript from '../zendeskScript/ZendeskScript';

function ShopifyLayout({ children }) {
   const accessToken = cookies.get('access_token');
   instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

   const hostParams = new URLSearchParams(window.location.search).get('host');

   const app = createApp({
      apiKey: process.env?.REACT_APP_SHOPIFY_API_KEY || '12345',
      host: hostParams,
   });

   const tokenInterval = useRef();

   useEffect(() => {
      const getSessionToken = async () => {
         await getShopifyToken(app).then((res) => {
            if (res) instance.defaults.headers.common.ShopifySessionToken = res;
         });
      };
      getSessionToken();
      tokenInterval.current = setInterval(getSessionToken, 10000);

      return () => clearInterval(tokenInterval.current);
      // eslint-disable-next-line
   }, []);

   return (
      <div
         style={{
            width: '85%',
            position: 'relative',
            transform: 'translateX(-50%)',
            left: '50%',
         }}
      >
         <ZendeskScript />
         <Page fullWidth>{children}</Page>
      </div>
   );
}

export default ShopifyLayout;
