import {
   Dialog,
   DialogContent,
   DialogTitle,
   Grid,
   IconButton,
   Slide,
   Stack,
   Typography,
} from '@mui/material';
import React, { useRef, useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from 'src/hooks/use-auth';
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';

const DemoSuccessDialog = forwardRef((props, ref) => {
   const { data, startFullMigration, loading } = props;

   const totalAmmount = data?.order?.total;

   const { user } = useAuth();

   const timer = useRef();

   const theme = useTheme();

   const [show, setShow] = useState(false);
   const [counter, setCounter] = useState(10);
   const [paymentDetail, setPaymentDetail] = useState({ date: null, id: null });

   const mainColor = theme.palette.primary.main;

   useImperativeHandle(ref, () => ({
      showDialog: (payment) => {
         setShow((current) => !current);

         let _detail = {
            date: payment.created_at,
            id: payment.payment_id,
         };

         setPaymentDetail(() => _detail);

         let _count = counter;
         timer.current = setInterval(() => {
            _count--;
            setCounter(() => _count);

            if (_count === 0) {
               clearInterval(timer.current);
               startFullMigration();
               setShow((current) => !current);
            }
         }, 1000);
      },
   }));

   useEffect(() => {
      const extractValues = () => {
         const paymentIdElement = document.getElementById('payment-success-id');
         const totalElement = document.getElementById('payment-success-total');

         const paymentId = paymentIdElement ? paymentIdElement.textContent.trim() : '';
         const total = totalElement ? totalElement.textContent.trim().replace(/[^\d.-]/g, '') : '';

         window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
            event: 'purchase',
            paymentId: paymentId,
            total: total,
         });
      };
      extractValues();
   }, []);

   return (
      <Dialog
         open={show}
         maxWidth="sm"
         TransitionComponent={Slide}
         transitionDuration={500}
         keepMounted
      >
         <DialogTitle sx={{ px: 2, py: 0 }}>
            <Stack direction="row" justifyContent="end" alignItems="center">
               <IconButton
                  aria-label="close"
                  onClick={() => setShow((current) => !current)}
                  sx={{
                     color: (theme) => theme.palette.grey[500],
                  }}
               >
                  <CloseIcon />
               </IconButton>
            </Stack>
         </DialogTitle>

         <DialogContent dividers>
            {/* Complimentary */}
            <Typography variant="subtitle1" textAlign="center">
               Thank you! Your payment has been received successfully
            </Typography>

            {/* Detail */}
            <Grid container sx={{ mt: 2, mb: 3 }} spacing={2}>
               <Grid item xs={6}>
                  <Typography textAlign="end" variant="subtitle2">
                     Payment ID:
                  </Typography>
               </Grid>
               <Grid item xs={6}>
                  <Typography textAlign="center" variant="body2" id="payment-success-id">
                     #{paymentDetail.id}
                  </Typography>
               </Grid>

               {/* Date */}
               <Grid item xs={6}>
                  <Typography textAlign="end" variant="subtitle2">
                     Date
                  </Typography>
               </Grid>
               <Grid item xs={6}>
                  <Typography textAlign="center" variant="body2">
                     {new Date(paymentDetail.date).toDateString().replace(/^\S+\s/, '')}
                  </Typography>
               </Grid>

               {/* Name */}
               <Grid item xs={6}>
                  <Typography textAlign="end" variant="subtitle2">
                     Customer Name:
                  </Typography>
               </Grid>
               <Grid item xs={6}>
                  <Typography textAlign="center" variant="body2">
                     {user?.user?.name}
                  </Typography>
               </Grid>

               {/* Email */}
               <Grid item xs={6}>
                  <Typography textAlign="end" variant="subtitle2">
                     Customer Email:
                  </Typography>
               </Grid>
               <Grid item xs={6}>
                  <Typography textAlign="center" variant="body2">
                     {user?.user?.email}
                  </Typography>
               </Grid>

               {/* Amount */}
               <Grid item xs={6}>
                  <Typography textAlign="end" variant="subtitle2">
                     Total:
                  </Typography>
               </Grid>
               <Grid item xs={6}>
                  <Typography textAlign="center" variant="body2" id="payment-success-total">
                     ${totalAmmount}
                  </Typography>
               </Grid>
            </Grid>

            {/* Countdown */}
            <Typography variant="subtitle1" textAlign="center">
               Your migration will be started full after{' '}
               <span style={{ color: mainColor }}>{counter} seconds</span>
            </Typography>

            {/* Actions */}
            <Stack alignItems="center" sx={{ mt: 1 }}>
               <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={() => {
                     clearInterval(timer.current);
                     setCounter(() => 0);
                     startFullMigration();
                     setShow((current) => !current);
                  }}
               >
                  Start Full Now
               </LoadingButton>
            </Stack>
         </DialogContent>
      </Dialog>
   );
});

export default DemoSuccessDialog;
