import PropTypes from 'prop-types';
import { Box, Link, Stack, Typography } from '@mui/material';

const reviewPages = [
   {
      page: 'trustpilot',
      src: '/assets/justify_logos/trustpilot_review_white.svg',
      href: 'https://www.trustpilot.com/review/litextension.com',
   },
   {
      page: 'shopify',
      src: '/assets/justify_logos/shopify_app_store_review_white.svg',
      href: 'https://apps.shopify.com/litextension-shopify-migration-app',
   },
   {
      page: 'serchen',
      src: '/assets/justify_logos/serchen_review_white.svg',
      href: 'https://www.serchen.com/company/litextension',
   },
   {
      page: 'g2',
      src: '/assets/justify_logos/g2_review_white.svg',
      href: 'https://www.g2.com/products/litextension/reviews',
      width: '28px',
      margin: '0 10px',
   },
   {
      page: 'youtube',
      src: '/assets/justify_logos/youtube_review.svg',
      href: 'https://litextension.com/success-stories-litextension.html)',
   },
];

export const Layout = (props) => {
   const { children } = props;

   return (
      <Box
         sx={{
            backgroundColor: 'background.default',
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: {
               xs: 'column-reverse',
               md: 'row',
            },
         }}
      >
         <Box
            sx={{
               alignItems: 'center',
               backgroundColor: 'neutral.800',
               backgroundImage: 'url("/assets/gradient-bg.svg")',
               backgroundPosition: 'top center',
               backgroundRepeat: 'no-repeat',
               color: 'common.white',
               display: 'flex',
               flex: {
                  xs: '0 0 auto',
                  md: '1 1 auto',
               },
               justifyContent: 'center',
               p: {
                  xs: 4,
                  md: 8,
               },
               position: 'relative',
            }}
         >
            <Box maxWidth="md">
               <Stack alignItems="center" sx={{ pb: 5 }}>
                  <img src="/assets/logo_lit.svg" style={{ width: '300px' }} />
               </Stack>
               <Typography sx={{ mb: 1 }} variant="h4" textAlign="center">
                  #1 Shopping Cart Migration Expert
               </Typography>
               <Typography color="text.secondary" sx={{ mb: 4 }}>
                  Migrate products, customers, orders, blogs, and other related entities from your
                  online store (or CSV/Excel/Database files) to the desire shopping cart securely
                  and accurately. 140+ eCommerce platforms supported, 300K stores migrated so far.
               </Typography>
            </Box>
            <Stack
               alignItems="center"
               direction="column"
               flexWrap="wrap"
               justifyContent="center"
               sx={{
                  '& > *': {
                     flex: '0 0 auto',
                  },
                  position: 'absolute',
                  bottom: 10,
               }}
            >
               <Typography fontSize=".875rem">
                  Trusted by 200.000+ merchants over the world
               </Typography>
               <Stack direction="row" mt={1}>
                  {reviewPages.map((page, index) => (
                     <Link key={page.page} href={page.href} target="_blank">
                        <img
                           src={page.src}
                           style={{
                              width: page.width ?? 100,
                              height: 25,
                              margin: page.margin ?? '',
                              paddingRight: index !== reviewPages.length - 1 ? 10 : 0,
                           }}
                        />
                     </Link>
                  ))}
               </Stack>
            </Stack>
         </Box>
         <Box
            sx={{
               backgroundColor: 'background.paper',
               display: 'flex',
               flex: {
                  xs: '1 1 auto',
                  md: '0 0 auto',
               },
               flexDirection: 'column',
               justifyContent: {
                  md: 'center',
               },
               maxWidth: '100%',
               p: {
                  xs: 4,
                  md: 8,
               },
               width: {
                  md: 600,
               },
            }}
         >
            <div>{children}</div>
         </Box>
      </Box>
   );
};

Layout.propTypes = {
   children: PropTypes.node,
};
