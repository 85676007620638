import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   globalOnField: false,
};

const reducers = {
   setGlobalOnField: (state, action) => {
      state.globalOnField = action.payload;
   },
};

export const slice = createSlice({
   name: 'globalOnField',
   initialState,
   reducers,
});

export const { reducer } = slice;