import React, { createContext } from 'react';
import { useState } from 'react';
import WarningPopper from 'src/pages/dashboard/CustomComponents/WarningPopper';

const initialValues = {
   anchorEl: null,
   setAnchorEl: () => {},
   arrowRef: null,
   setArrowRef: () => {},
   warning: <></>,
   setWarning: () => {},
};

export const WarningPopperContext = createContext(initialValues);

function WarningPopperProvider({ children }) {
   const [warning, setWarning] = useState({});
   const [anchorEl, setAnchorEl] = useState(null);
   const [arrowRef, setArrowRef] = useState(null);

   return (
      <WarningPopperContext.Provider
         value={{
            warning,
            setWarning,
            anchorEl,
            setAnchorEl,
            arrowRef,
            setArrowRef,
         }}
      >
         {children}
         <WarningPopper
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            arrowRef={arrowRef}
            setArrowRef={setArrowRef}
            warning={warning}
         />
      </WarningPopperContext.Provider>
   );
}

export default WarningPopperProvider;
