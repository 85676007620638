import { useLocation } from 'react-router-dom';

export const useMigrationId = (index) => {
   // It's so stupid, I could have just use the useParams to get the Id, but what's done is done, it is what it is
   // And I'm to lazy to change all of them since it's been running perfectly, but feel free to change it

   const { pathname } = useLocation();
   const id = pathname?.split('/')[index]; //To get id from the URL

   return id;
};
