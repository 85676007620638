import { Snackbar } from "@mui/material";

const CustomSnackBar = (props) => {
   const { open, children, onClose } = props;
   return (
      <Snackbar open={open} autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={onClose}>
         {children}
      </Snackbar>
   );
};

export default CustomSnackBar