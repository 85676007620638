import React from 'react';
import { Paper, Popper, Stack, SvgIcon, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoCircleIcon from '@untitled-ui/icons-react/build/esm/InfoCircle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
   arrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      boxSizing: 'border-box',
      '&::before': {
         content: '""',
         margin: 'auto',
         display: 'block',
         width: '100%',
         height: '100%',
         backgroundColor: theme.palette.error.light,
         transform: 'rotate(45deg)',
      },
   },
}));

function WarningPopper(props) {
   const { anchorEl, arrowRef, setArrowRef, warning, setAnchorEl } = props;

   const classes = useStyles();

   const renderWarning = () => (
      <Paper
         elevation={10}
         sx={{
            p: 2,
            maxWidth: '450px',
            display: 'flex',
            background: (theme) => theme.palette.error.light,
         }}
      >
         <Stack spacing={2}>
            <Stack direction="row" alignItems="center">
               <SvgIcon sx={{ mr: 1 }} color="error">
                  <InfoCircleIcon />
               </SvgIcon>
               <Typography variant="h6">{warning?.title || ''}</Typography>
               <SvgIcon
                  sx={{ color: '#a0a4aa', cursor: 'pointer', ml: 'auto', mr: 0 }}
                  onClick={() => setAnchorEl(() => null)}
               >
                  <CloseIcon />
               </SvgIcon>
            </Stack>

            <Typography
               dangerouslySetInnerHTML={{ __html: warning?.content || '' }}
               variant="body2"
            />
         </Stack>
      </Paper>
   );

   return (
      <React.Fragment>
         <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            modifiers={[
               {
                  name: 'flip',
                  enabled: false,
                  options: {
                     altBoundary: true,
                     rootBoundary: 'viewport',
                     padding: 8,
                  },
               },
               {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                     altAxis: true,
                     altBoundary: true,
                     tether: false,
                     rootBoundary: 'viewport',
                     padding: 8,
                  },
               },
               {
                  name: 'arrow',
                  enabled: true,
                  options: {
                     element: arrowRef,
                  },
               },
            ]}
         >
            <span className={classes.arrow} ref={setArrowRef} />
            {renderWarning()}
         </Popper>
      </React.Fragment>
   );
}

export default WarningPopper;
