import { Checkbox } from '@mui/material';
import { useField } from 'formik';
import React, { useEffect } from 'react';

function ConfirmEntitiesCheckbox(props) {
   const { name = '', setHasChanged, data } = props;

   const [{ value }, , { setValue }] = useField(name);

   useEffect(() => {
      // If the entity is false, we set the checked to false as well because the initialValues are all true
      if (!data?.config_notice?.[name]) setValue(false);
      // eslint-disable-next-line
   }, [name]);

   const applyCheck = (checked) => {
      setValue(checked);
      setHasChanged(() => true);
   };

   return (
      <Checkbox
         sx={{ padding: 0 }}
         checked={value}
         onChange={(e) => applyCheck(e.target.checked)}
      />
   );
}

export default ConfirmEntitiesCheckbox;
