import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   errorAlert: false,
   warningAlert: false,
   successAlert: false,
   inforAlert: false,
   messageAlert: '',
};

const reducers = {
   setErrorAlert: (state, action) => {
      state.errorAlert = action.payload;
   },
   setWarningAlert: (state, action) => {
      state.warningAlert = action.payload;
   },
   setSuccessAlert: (state, action) => {
      state.successAlert = action.payload;
   },
   setInforAlert: (state, action) => {
      state.inforAlert = action.payload;
   },
   setMessageAlert: (state, action) => {
      state.messageAlert = action.payload;
   },
};

export const slice = createSlice({
   name: 'toastAlert',
   initialState,
   reducers,
});

export const { reducer } = slice;
