import { Card, CardContent, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import DefaultGrow from '../../CustomComponents/DefaultGrow';
import CustomCardWarningHeader from '../../CustomComponents/CustomCardWarningHeader';

const CustomFormControlLabelV2 = styled(FormControlLabel)({
   height: 32,
   marginRight: 0,
   width: 'fit-content',
});

function SmartWarning(props) {
   const { setSmartWarning } = props;

   return (
      <React.Fragment>
         <DefaultGrow index={5}>
            <Card>
               <CustomCardWarningHeader />
               <Divider />
               <CardContent
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     flexDirection: 'column',
                  }}
               >
                  <Typography variant="body2">
                     You are running smart update. All supported fields will be overwritten (Name,
                     SKU, quanty, status, URL key, product price, sales price, product-categories
                     link)
                  </Typography>
                  <CustomFormControlLabelV2
                     control={<Checkbox />}
                     label={<Typography variant="subtitle2">I Accept</Typography>}
                     onChange={(e) => setSmartWarning(() => e.target.checked)}
                  />
               </CardContent>
            </Card>
         </DefaultGrow>
      </React.Fragment>
   );
}

export default SmartWarning;
