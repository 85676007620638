import { Slide } from '@mui/material';
import React from 'react';

function DefaultSlide(props) {
   const {
      children,
      onMount = true,
      easing = { enter: 'ease-out', exit: 'ease-in' },
      timeout = 300,
      unmountOnExit,
      index = 0,
      direction = 'left',
      transition = 100
   } = props;

   return (
      <Slide
         in={onMount}
         unmountOnExit={unmountOnExit}
         easing={easing}
         timeout={timeout + index * transition}
         direction={direction}
      >
         {children}
      </Slide>
   );
}

export default DefaultSlide;
