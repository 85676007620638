import React from 'react';
import DefaultGrow from '../../CustomComponents/DefaultGrow';
import { Card, CardContent, CardHeader, Divider, Link, Stack, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function MigrationHelper(props) {
   const { isDemo, targetStore = '' } = props;
   return (
      <React.Fragment>
         <DefaultGrow index={1}>
            <Card>
               <CardHeader
                  title={
                     <Stack direction="row" spacing={0.5}>
                        <img
                           src="/assets/modern_lightbulb.svg"
                           style={{
                              height: '20px',
                              marginRight: 4,
                           }}
                        />
                        <Typography variant="h5" display="flex" alignItems="center">
                           Do you know?
                        </Typography>
                     </Stack>
                  }
               />
               <Divider />
               <CardContent>
                  <Stack spacing={0}>
                     <Typography variant="body2" sx={{ position: 'relative' }}>
                        <FiberManualRecordIcon sx={{ height: '10px' }} color="primary" />
                        Migration is running in the background, you don't have to keep this browser
                        open until migration completes. It is safe to close browser, turn off you
                        computer and grab some{' '}
                        <img
                           src="/assets/coffee_cup.svg"
                           style={{
                              height: '20px',
                              marginRight: 4,
                              verticalAlign: 'text-bottom',
                           }}
                        />
                        . You will get a notification email when migration completes.
                     </Typography>

                     <Typography variant="body2" sx={{ position: 'relative' }}>
                        <FiberManualRecordIcon sx={{ height: '10px' }} color="primary" />
                        If migration gets slow at some point, please pay attention to the{' '}
                        <img
                           src="/assets/information-yellow.png"
                           style={{
                              height: '20px',
                              marginRight: 4,
                              verticalAlign: 'text-bottom',
                           }}
                        />
                        icon next to the migration progress, it explained detected issues. This icon
                        will disappear when speed gets back to normal.
                     </Typography>

                     <Typography variant="body2" sx={{ position: 'relative' }}>
                        <FiberManualRecordIcon sx={{ height: '10px' }} color="primary" />
                        If you want to stop the migration process, please request us via chat or
                        email.
                     </Typography>
                     
                     {targetStore === 'shopify' && (
                        <Typography variant="body2" sx={{ position: 'relative' }}>
                           <FiberManualRecordIcon sx={{ height: '10px' }} color="primary" />
                           We highly recommend you to follow{' '}
                           <Link
                              target="_blank"
                              href="https://litextension.com/blog/set-up-shopify-store-after-migration/?utm_source=migrationrunning"
                           >
                              this checklist
                           </Link>{' '}
                           to complete setting up your new Shopify store
                        </Typography>
                     )}
                     {isDemo && (
                        <Typography variant="body2" sx={{ position: 'relative' }}>
                           <FiberManualRecordIcon sx={{ height: '10px' }} color="primary" />
                           For Demo Migration, we do not support migrating <strong>
                              reviews
                           </strong>{' '}
                           details, but they will be imported when a Full Migration is performed.
                        </Typography>
                     )}
                  </Stack>
               </CardContent>
            </Card>
         </DefaultGrow>
      </React.Fragment>
   );
}

export default MigrationHelper;
