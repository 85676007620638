import { Card, CardContent, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import DefaultGrow from 'src/pages/dashboard/CustomComponents/DefaultGrow';
import { useState } from 'react';
import { demoMigrationServices } from 'src/api/services/demoMigration';
import UpdateButton from '../ConfirmComps/ConfirmUpdateButton';
import ConfirmEntitiesTable from '../ConfirmComps/ConfirmEntitiesTable';
import { entities } from 'src/locales/constant/arrayRender';
import { useAlert } from 'src/hooks/useAlert';
import { useMigrationId } from 'src/hooks/useMigrationId';
import { useSettings } from 'src/hooks/use-settings';
import ConfirmUpdatedTable from '../ConfirmComps/ConfirmUpdatedTable';
import { isEmpty } from 'lodash';
import { usePathname } from 'src/hooks/use-pathname';

const initialValues = {
   reviews: true,
   coupons: true,
   blogs: true,
   pages: true,
   taxes: true,
   manufacturers: true,
   categories: true,
   orders: true,
   customers: true,
   products: true,
};

function ConfirmEntities(props) {
   const { afterHover, data, getOrderMigration } = props;

   const pathname = usePathname();

   const totalEntitiesPrice = data?.order?.entities_price;
   const entities_select = data?.order?.entities_select;
   const entities_fields = data?.order?.entities ? Object.keys(data?.order?.entities) : [];
   const entities_update = data?.order?.entities_update;
   const entities_update_price = data?.order?.entities_update_price;

   const renderEntities =
      entities_select && Array.isArray(entities_select) && entities_select?.length !== 0
         ? entities.filter((entity) => entities_select?.includes(entity.field))
         : entities.filter((entity) => data?.config_notice?.[entity.field]);

   const totalEntities = entities_fields?.reduce(
      (prev, current) => parseInt(prev) + parseInt(data?.order?.entities[current] || 0),
      0
   );
   const totalEntitiesUpdate = entities_fields?.reduce(
      (prev, current) => parseInt(prev) + parseInt(entities_update?.[current] || 0),
      0
   );

   const alert = useAlert();

   const settings = useSettings();

   const migrationId = useMigrationId(2);

   const [hasChanged, setHasChanged] = useState(false);
   const [loading, setLoading] = useState(false);

   const submit = async (values) => {
      let _payload = {
         migration_id: migrationId,
         recent_entities: [],
      };

      setLoading((current) => !current);
      try {
         const recentEntities = renderEntities
            .filter((entity) => values[entity.field])
            .map((entity) => entity.field);

         _payload.recent_entities = recentEntities;

         await demoMigrationServices.post.updateEntities(_payload).then(async (res) => {
            if (res.data.success) {
               setHasChanged(() => false);
               alert('success', 'Update Entities Success');
               await getOrderMigration();
            }
         });
      } catch (error) {
         console.log(error);
         alert('error', 'Update Entities Fail');
      }
      setLoading((current) => !current);
   };

   return (
      <React.Fragment>
         <Grid item xs={15} md={6}>
            <Formik initialValues={initialValues} onSubmit={(values) => submit(values)}>
               {(formik) => (
                  <Form style={{ height: '100%' }}>
                     <DefaultGrow>
                        <Stack spacing={2}>
                           <Card
                              sx={{ height: '100%' }}
                              onMouseEnter={() => afterHover('total_entities')}
                           >
                              {/* Header */}
                              <CardHeader
                                 title={
                                    <Typography variant="h5">
                                       Total {pathname.includes('smart') ? 'New' : null} Entities:{' '}
                                       {totalEntities}($
                                       {totalEntitiesPrice})
                                    </Typography>
                                 }
                                 sx={{
                                    background: (theme) => {
                                       return settings.paletteMode === 'light'
                                          ? theme.palette.primary.light
                                          : theme.palette.primary.darkest;
                                    },
                                 }}
                              />

                              <Divider />
                              {/* Content */}
                              <CardContent sx={{ padding: 1 }}>
                                 {/* Table */}
                                 <ConfirmEntitiesTable
                                    data={data}
                                    setHasChanged={setHasChanged}
                                    renderEntities={renderEntities}
                                 />
                                 {/* Button */}
                                 {hasChanged && (
                                    <UpdateButton hasChanged={hasChanged} loading={loading} />
                                 )}
                              </CardContent>
                           </Card>
                           {!isEmpty(entities_update) && (
                              <Card
                                 sx={{ height: '100%' }}
                                 onMouseEnter={() => afterHover('total_entities')}
                              >
                                 {/* Header */}
                                 <CardHeader
                                    title={
                                       <Typography variant="h5">
                                          Total Entities Update: {totalEntitiesUpdate}($
                                          {entities_update_price})
                                       </Typography>
                                    }
                                    sx={{
                                       background: (theme) => {
                                          return settings.paletteMode === 'light'
                                             ? theme.palette.primary.light
                                             : theme.palette.primary.darkest;
                                       },
                                    }}
                                 />

                                 <Divider />
                                 {/* Content */}
                                 <CardContent sx={{ padding: 1 }}>
                                    {/* Table */}
                                    <ConfirmUpdatedTable entitiesUpdate={entities_update} />
                                 </CardContent>
                              </Card>
                           )}
                        </Stack>
                     </DefaultGrow>
                  </Form>
               )}
            </Formik>
         </Grid>
      </React.Fragment>
   );
}

export default ConfirmEntities;
