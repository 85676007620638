import { Stack, Typography } from '@mui/material';
import React from 'react';

function ConfirmHeaderNote(props) {
   const { updateType } = props;

   const renderHeaderNote = () => {
      switch (updateType) {
         case 'recent':
            return (
               <Typography variant="body2">
                  This migration mode will import <strong>New Data Only</strong>
               </Typography>
            );
         case 'smart':
            return (
               <Stack>
                  <Typography variant="body2">
                     <strong>Smart Update </strong> allows you to:
                  </Typography>
                  <Typography variant="body2">
                     1. Update migrated data on Target Store with latest data from Source. Fields
                     that supported in this update: Product(name, SKU, quantity, status, URL key,
                     product price, sales price, product-categories link).
                  </Typography>
                  <Typography variant="body2">
                     2. Recent Migration: migrate ONLY NEW ENTITIES added to your Source Store since
                     you performed last Full Migration.
                  </Typography>
                  <Typography variant="body2">
                     <strong>Smart Update</strong> is perfect when you need an effective update from
                     your Source Store to your Target Store after you finished Full Migration for
                     while.
                  </Typography>
               </Stack>
            );

         default:
            return null;
      }
   };

   return <React.Fragment>{renderHeaderNote()}</React.Fragment>;
}

export default ConfirmHeaderNote;
