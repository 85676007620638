import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   routerTabProfile: 'profile',
};

const reducers = {
   setAccountTab: (state, action) => {
      state.routerTabProfile = action.payload;
   },
};

export const slice = createSlice({
   name: 'routerTab',
   initialState,
   reducers,
});

export const { reducer } = slice;
