import { Card, Link, Typography } from '@mui/material';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';
import { thunks } from 'src/thunks/routerTabThunks';
import DefaultGrow from '../../CustomComponents/DefaultGrow';
import { useAuth } from 'src/hooks/use-auth';

function BalanceReminder(props) {
   const { data } = props;

   const { user } = useAuth();

   const neededAmount = data?.order?.total - user?.user?.balance || 0;

   const isStaff = user?.user?.group === 2;

   const dispatch = useDispatch();

   return (
      <React.Fragment>
         <DefaultGrow index={4}>
            <Card
               sx={{
                  padding: 2,
                  paddingLeft: 3,
               }}
            >
               <Link
                  color="text.primary"
                  component={RouterLink}
                  href={paths.dashboard.profile}
                  sx={{
                     alignItems: 'center',
                     display: 'inline-flex',
                  }}
                  underline="hover"
                  onClick={() => dispatch(thunks.setAccountTab('payments'))}
               >
                  <Typography variant="subtitle1">
                     You have ${user?.user?.balance} in your balance.{' '}
                     {neededAmount > 0 && !isStaff && (
                        <span>You need to pay ${neededAmount} more. </span>
                     )}
                  </Typography>
               </Link>
            </Card>
         </DefaultGrow>
      </React.Fragment>
   );
}

export default memo(BalanceReminder);
