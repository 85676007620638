import React from 'react';
import { CustomAutoComplete, CustomPopper } from '../CustomComponents/Custom';
import { TextField } from '@mui/material';
import { useField } from 'formik';

const options = [
   { value: 'shop_owner', label: 'Shop Owner' },
   { value: 'freelancer', label: 'Freelancer' },
   { value: 'web_agency', label: 'Web Agency' },
];

function CustomerType() {
   const [{ value: category }, { error: errorCategory, touched }, { setValue: setCategory }] =
      useField('category');

   const getCategory = options.find((option) => option.value === category);

   return (
      <CustomAutoComplete
         options={options}
         PopperComponent={CustomPopper}
         getOptionLabel={(option) => option.label}
         defaultValue={getCategory}
         fullWidth
         isOptionEqualToValue={(option, value) => option.value === value.value} //this is for the warning The value provided to Autocomplete is invalid
         onChange={(event, newValue) => setCategory(newValue.value)}
         renderInput={(params) => (
            <TextField
               {...params}
               inputProps={{ ...params.inputProps, readOnly: true }}
               error={errorCategory && touched ? errorCategory : null}
               helperText={errorCategory && touched ? errorCategory : null}
               label={
                  <label>
                     You are<span style={{ color: 'red' }}>*</span>
                  </label>
               }
               fullWidth
               name="categories"
            />
         )}
      />
   );
}

export default CustomerType;
