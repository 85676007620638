import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
import ConfirmMigration from 'src/pages/dashboard/MigrationStep/ConfirmMigration';
import DemoRunning from 'src/pages/dashboard/MigrationStep/DemoRunning';
import { paths } from 'src/paths';

const HomePage = lazy(() => import('src/pages/dashboard/index'));

// My things
const CreateMigration = lazy(() => import('src/pages/dashboard/CreateMigration'));
const Configuration = lazy(() => import('src/pages/dashboard/MigrationStep/Configuration'));
const DemoMigration = lazy(() => import('src/pages/dashboard/MigrationStep/DemoMigration'));
const FullMigration = lazy(() => import('src/pages/dashboard/MigrationStep/FullMigration'));
const CompleteMigration = lazy(() => import('src/pages/dashboard/MigrationStep/CompleteMigration'));
const MyProfile = lazy(() => import('src/pages/dashboard/MyProfile'));
const MigrationDetail = lazy(() => import('src/pages/dashboard/MigrationDetail'));
const AllInOne = lazy(() => import('src/pages/dashboard/AllInOne'));
const ConfirmOrder = lazy(() => import('src/pages/dashboard/ConfirmOrder'));
const PaymentSuccess = lazy(() => import('src/pages/dashboard/PaymentSuccess'));
const CustomMigration = lazy(() => import('src/pages/dashboard/CustomMigration'));
const ConfirmCustomMigration = lazy(() => import('src/pages/dashboard/ConfirmCustomMigration'));
const Checkout = lazy(() => import('src/pages/dashboard/Checkout'));
const ConfirmCheckout = lazy(() => import('src/pages/dashboard/ConfirmCheckout'));
const Welcome = lazy(() => import('src/pages/dashboard/Welcome'));
const LoginSso = lazy(() => import('src/pages/dashboard/LoginSso'));
const SpecializedMigration = lazy(() => import('src/pages/dashboard/SpecializedMigration'));
const PremiumSubscription = lazy(() => import('src/pages/dashboard/PremiumSubscription'));

export const dashboardRoutes = [
   {
      path: '/',
      element: (
         <DashboardLayout>
            <Suspense>
               <Outlet />
            </Suspense>
         </DashboardLayout>
      ),
      children: [
         {
            index: true,
            element: <HomePage />,
         },
         {
            path: 'profile',
            element: <MyProfile />,
         },
         {
            path: 'payment',
            element: <MyProfile />,
         },
         {
            path: 'orderHistory',
            element: <MyProfile />,
         },
         {
            path: paths.dashboard.createMigration,
            element: <CreateMigration />,
         },
         {
            path: 'create-migration/:migration_id',
            element: <CreateMigration />,
         },
         {
            path: 'create-migration/:migrationType',
            element: <CreateMigration />,
         },
         {
            path: 'remigrate/:migration_id',
            element: <CreateMigration />,
         },
         {
            path: paths.dashboard.configuration,
            element: <Configuration />,
         },
         {
            path: 'configuration/:migration_id',
            element: <Configuration />,
         },
         {
            path: paths.dashboard.migration,
            element: <DemoMigration />,
         },
         {
            path: 'migration/:migration_id',
            element: <DemoMigration />,
         },
         {
            path: paths.dashboard.runDemo,
            element: <DemoRunning />,
         },
         {
            path: 'demo-running/:migration_id',
            element: <DemoRunning />,
         },
         {
            path: paths.dashboard.fullMigration,
            element: <FullMigration />,
         },
         {
            path: 'full-migration/:migration_id',
            element: <FullMigration />,
         },
         {
            path: paths.dashboard.completeMigration,
            element: <CompleteMigration />,
         },
         {
            path: 'complete-migration/:migration_id',
            element: <CompleteMigration />,
         },
         {
            path: paths.dashboard.migrationDetail,
            element: <MigrationDetail />,
         },
         {
            path: 'migration-detail/:migration_id',
            element: <MigrationDetail />,
         },
         {
            path: paths.dashboard.confirmRecent,
            element: <ConfirmMigration />,
         },
         {
            path: 'confirm-recent/:migration_id',
            element: <ConfirmMigration />,
         },
         {
            path: paths.dashboard.confirmSmart,
            element: <ConfirmMigration />,
         },
         {
            path: 'confirm-smart/:migration_id',
            element: <ConfirmMigration />,
         },
         {
            path: paths.dashboard.allInOne,
            element: <AllInOne />,
         },
         {
            path: 'confirm-order',
            element: <ConfirmOrder />,
         },
         {
            path: 'confirm-order/:service_type',
            element: <ConfirmOrder />,
         },
         {
            path: 'payment-success',
            element: <PaymentSuccess />,
         },
         {
            path: paths.dashboard.customMigration,
            element: <CustomMigration />,
         },
         {
            path: paths.dashboard.confirmCustomMigration,
            element: <ConfirmCustomMigration />,
         },
         {
            path: paths.dashboard.checkout,
            element: <Checkout />,
         },
         {
            path: 'check-out/:checkout_token',
            element: <Checkout />,
         },
         {
            path: paths.dashboard.confirmCheckout,
            element: <ConfirmCheckout />,
         },
         {
            path: 'welcome',
            element: <Welcome />,
         },
         {
            path: '/sso/login/',
            element: <LoginSso />,
         },
         {
            path: paths.dashboard.specialized,
            element: <SpecializedMigration />,
         },
         {
            path: paths.dashboard.subscription,
            element: <PremiumSubscription />,
         },
      ],
   },
];
