import React, { useEffect, createContext } from 'react';
// import { advertiseServices } from 'src/api/services/advertise';

const initialValues = { banners: [] };

export const AdsBannerContext = createContext(initialValues);

function AdsBannerProvider({ children }) {
   // const [banners, setBanners] = useState([]);

   useEffect(() => {
      // advertiseServices.get.getAdsBanner().then((res) => {
      //    if (res.data.success) setBanners(() => res.data.banners);
      // });
   }, []);

   return <AdsBannerContext.Provider value={{}}>{children}</AdsBannerContext.Provider>;
}

export default AdsBannerProvider;
