import { lazy } from 'react';
import { Layout as AuthModernLayout } from 'src/layouts/auth/modern-layout';

const ForgotPasswordModernPage = lazy(() => import('src/pages/auth-demo/forgot-password/modern'));
const LoginModernPage = lazy(() => import('src/pages/auth-demo/login/modern'));
const RegisterModernPage = lazy(() => import('src/pages/auth-demo/register/modern'));
const ResetPasswordModernPage = lazy(() => import('src/pages/auth-demo/ResetPassword/ResetPassword'));

export const authDemoRoutes = [
   {
      path: 'login',
      element: (
         <AuthModernLayout>
            <LoginModernPage />
         </AuthModernLayout>
      ),
   },
   {
      path: 'register',
      element: (
         <AuthModernLayout>
            <RegisterModernPage />
         </AuthModernLayout>
      ),
   },
   {
      path: 'forgot-password',
      element: (
         <AuthModernLayout>
            <ForgotPasswordModernPage />
         </AuthModernLayout>
      ),
   },
   {
      path: 'reset-password',
      element: (
         <AuthModernLayout>
            <ResetPasswordModernPage />
         </AuthModernLayout>
      ),
   },
];
