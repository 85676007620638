import axios from 'src/utils/instantAxios.js';

export const migrationServices = {
   get: {
      getMigrationList: (payload) =>
         axios.get('/api/migration', {
            params: {
               ...payload,
            },
         }),
      getMigrationDetail: (payload) => axios.get(`/api/migration/${payload}`),
      getAdditionalOption: () => axios.get('api/addition-option'),
      getOrderMigration: (payload) => axios.get(`api/create-migration/get-order/${payload}`),
   },
};
